import { Col, Container, Row } from "react-bootstrap";
import OtpInputWithValidation from "./OtpInputWithValidation";
import { useLocation } from 'react-router-dom';
import './otp.css'
import Timer from "./Timer";

function OtpAuth() {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    console.log('Param: ', queryParams.get('email'))
    const email = queryParams.get('email');
    const phone = queryParams.get('phone');
    const authKey = email ? email : phone;

    return (
        <div style={{justifyContent:'center',alignItems:'center',display:'flex'}}>
            <Container style={{borderRadius:'10px', border:'1px solid black', marginTop:'5%',width:'50%',height:'50%',justifyContent:'center',alignItems:'center',display:'flex'}}>
                
                    <Col lg={8} >
                        <div>
                        <div style={{ padding: '20px' }}>
                            <h3 style={{ color: 'blue' }}><u>Login</u></h3>
                            <p style={{
                             textAlign: "center",
                            }}>We have sent you <b>one time password</b> to: </p>
                            
                            <h6 style={{
                                fontSize: '18px', textAlign: 'center', color: 'red',
                            }}>{authKey}</h6>
                            <Timer />
                        </div>
                        <div>
                            <OtpInputWithValidation numberOfDigits={6} />

                        </div>
                        </div>

                    </Col>

                
            </Container>
        </div>
    )
}

export default OtpAuth