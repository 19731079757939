import React, { useState, useEffect } from 'react';
import './otp.css'

const Timer = () => {
  const [seconds, setSeconds] = useState(120); // Initial number of seconds

  useEffect(() => {
    const timer = setInterval(() => {
      setSeconds(prevSeconds => prevSeconds - 1);
    }, 1000);

    // Cleanup function to clear the interval when the component is unmounted
    return () => clearInterval(timer);
  }, []); // Empty dependency array ensures that the effect runs only once on mount

  useEffect(() => {
    // Check if the timer has reached zero
    if (seconds === 0) {
      // Perform any actions you want when the timer reaches zero
      console.log('Timer reached zero!');
    }
  }, [seconds]);

  const formatTime = () => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  return (
    <div>
      {/* <p style={{color:'#ff0039bd',textAlign:'center',fontSize:'20px'}}>Countdown Timer</p> */}
      <p  style={{textAlign:'center',fontSize:'20px',color:'blue',lineHeight:'2'}}>{formatTime()}</p>
    </div>
  );
};

export default Timer;
