
import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import { Routes } from 'react-router-dom';
import Home from './Home';
import Login from './components/login';
import Merchandise from './components/merch'
import OtpAuth from './components/otpAuth/OtpAuth';
import { useEffect } from 'react';
import CategoryPage from './components/category';
import Cart from './components/cart';
import Dashboard from './components/dashboard'
import PrivicyPolicy from './components/PrivicyPolicy'
import ReactGA from 'react-ga';
  const TRACKING_ID = "G-8M1MNTQGT6"; // measurement_ID
  ReactGA.initialize(TRACKING_ID);

function App() {
  useEffect(() => {
    ReactGA.pageview(window.location.pathname);
  }, []);
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/login" element={<Login/>} />
        <Route path='/otp-auth' element={<OtpAuth />} />
        <Route path="/merchandise" element={<Merchandise/>} />
        <Route path="/coffee-mugs" element={<CategoryPage categoryName="Coffee Mugs" />} />
        <Route path="/key-chains" element={<CategoryPage categoryName="Key Chains" />} />
        <Route path="/water-bottles" element={<CategoryPage categoryName="Water Bottles" />} />
        {/* <Route path="/notebooks" element={<CategoryPage categoryName="Notebooks" />} /> */}
        <Route path="/t-shirts" element={<CategoryPage categoryName="T-Shirts" />} />
        <Route path="/personal-badges" element={<CategoryPage categoryName="Personal Badges" />} />
        <Route path="/Cushions" element={<CategoryPage categoryName="Cushions" />} />
        {/* <Route path="/comic-books" element={<CategoryPage categoryName="Comic Books" />} /> */}
        <Route path="/cart" element={<Cart/>} />
        <Route path="/dashboard" element={<Dashboard/>} />
        <Route path='/PrivicyPolicy' element={<PrivicyPolicy/>}/>



        {/* Add more routes here if needed */}
      </Routes>
    </Router>
  );
};

export default App;

//check
//check again