import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link, useNavigate } from 'react-router-dom';
import swal from 'sweetalert2';
import logo from './logo.png'; // Import your image

const Dashboard = () => {
  const [orders, setOrders] = useState([]);
  const navigate = useNavigate();
  const handleClick = () => {
    localStorage.removeItem("master-app");
    navigate("/");
  };

  const [reviewForm, setReviewForm] = useState({ rating: '', review: '' });
  const [showReviewsOverlay, setShowReviewsOverlay] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);


  const lightboxStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.8)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
  };

  const lightboxContentStyle = {
    background: 'white',
    padding: '20px',
    borderRadius: '10px',
    width: '80%',
    position: 'relative',
    maxWidth: '80%',
    maxHeight: '80%',
    overflowY: 'auto',
  };

  const closeButtonStyle = {
    position: 'absolute',
    top: '10px',
    right: '10px',
    background: 'none',
    border: '1px solid black',
    cursor: 'pointer',
    fontSize: '1.5rem',
    zIndex: '99999',
    color:'black'
  }; 

  const openReviewsOverlay = () => {
    setShowReviewsOverlay(true);
  };

  const closeReviewsOverlay = () => {
    setShowReviewsOverlay(false);
  };

  const handleReviewFormChange = (e) => {
    const { name, value } = e.target;
    setReviewForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const handleReviewSubmit = async (e) => {
    e.preventDefault();

    const userId = JSON.parse(localStorage.getItem('master-app'))?.userId; // Retrieve user ID from localStorage
    const { rating, review } = reviewForm;
  
    const reviewData = {
      reviewedAt: new Date().toISOString(),
      rating: Number(rating),
      review,
    };
  
    console.log('Review Data:', reviewData);
    
    try {
      const response = await axios.post(`https://myadventurebook.org/review/createReview/${selectedProduct}/${userId}`, reviewData);
  
      console.log('Response:', response.data);
  
      if (response.data.status) {
        swal.fire({
          title: 'Success',
          text: 'Review added successfully!',
          icon: 'success',
        }).then(() => {
            // Delayed refresh after 2 seconds
            setTimeout(() => {
              window.location.reload();
            }, 200);
          });
        closeReviewsOverlay();
        
      } 
      else if(response.data.message==="duplicate") {
        swal.fire({
          title: 'Error',
          text: 'Already Reviewed',
          icon: 'error',
        });
      }
      else {
        swal.fire({
          title: 'Error',
          text: 'Failed to add review!',
          icon: 'error',
        });
      }
    } catch (error) {
      console.error('Error adding review:', error);
      swal.fire({
        title: 'Error',
        text: "You haven't bought this product, buy this product to add a review.",
        icon: 'error',
      });
    }
   
  };
  const handleHover = (e) => {
    const box = e.target;
    box.style.transform = 'translateX(-5px)';
    setTimeout(() => {
      box.style.transform = 'translateX(5px)';
      setTimeout(() => {
        box.style.transform = 'translateX(-3px)';
        setTimeout(() => {
          box.style.transform = 'translateX(3px)';
          setTimeout(() => {
            box.style.transform = 'translateX(-2px)';
            setTimeout(() => {
              box.style.transform = 'translateX(2px)';
              setTimeout(() => {
                box.style.transform = 'translateX(0)';
              }, 100);
            }, 100);
          }, 100);
        }, 100);
      }, 100);
    }, 100);
  };
  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const storedData = JSON.parse(localStorage.getItem('master-app'));
        const userId = storedData ? storedData.userId : null; // Retrieve user ID from localStorage
                const response = await axios.get(`https://myadventurebook.org/order/getproduct/${userId}`);

        if (response.data.status) {
          setOrders(response.data.data);
        } else {
          console.error('Failed to fetch order data');
        }
      } catch (error) {
        console.error('Error fetching order data:', error);
      }
    };

    fetchOrders();
  }, []);


  const sliceAndModifyDate = (inputText, numDaysToAdd) => {
    const slicedText = inputText.slice(0, 10); // Slicing the first 10 characters (yyyy-mm-dd format)
    const dateObject = new Date(slicedText); // Convert slicedText to Date object

    // Add numDaysToAdd days to the date
    dateObject.setDate(dateObject.getDate() + numDaysToAdd);
    
    return dateObject;
  };


  return (
    <div className="DashboardPage" style={{ fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif' }}>
          <header style={{ backgroundColor: 'white', color: '#333', padding: '20px' }}>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px',gap:'1vw'}}>
        <Link style={{textDecoration:'None', textAnchor:'None'}} to="/">
          <div
            style={{
              width: '8vw',
              height: '8vw',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img src={logo} alt="Logo" style={{ maxWidth: '100%', maxHeight: '100%', cursor: 'pointer' }} />
          </div>
          </Link>
          <Link style={{textDecoration:'None', textAnchor:'None'}} to="/merchandise">
          <div
            style={{
              width: '16vw',
              height: '8vw',
              backgroundColor: '#CFF27E', 
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              transition: 'transform 0.2s ease-in-out',
              borderRadius:'10px',
              fontSize:'1.5vw'

            }}
            onMouseEnter={handleHover}
            onMouseLeave={(e) => {
              e.target.style.transform = 'translateX(0)';
            }}
          > 
          Merchandise
          </div>
          </Link>
          {!localStorage.getItem("master-app") ? (
             <Link style={{textDecoration:'None'}} to="/login">
             <div
               style={{
                 width: '8vw',
                 height: '8vw',
                 backgroundColor: '#ADF7B6', 
                 cursor: 'pointer',
                 display: 'flex',
                 alignItems: 'center',
                 justifyContent: 'center',
                 transition: 'transform 0.2s ease-in-out',
                 borderRadius:'10px',
                 fontSize:'1.5vw'
   
               }}
               onMouseEnter={handleHover}
               onMouseLeave={(e) => {
                 e.target.style.transform = 'translateX(0)';
               }}
             > 
             Login
             </div>
             </Link>
          ) :
           (
            <div
              style={{
                width: '8vw',
                height: '8vw',
                backgroundColor: '#ADF7B6', 
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                transition: 'transform 0.2s ease-in-out',
                borderRadius:'10px',
                fontSize:'1.5vw'
  
              }}
              onMouseEnter={handleHover}
              onMouseLeave={(e) => {
                e.target.style.transform = 'translateX(0)';
              }}
              onClick={handleClick}
            > 
            Logout
            </div>
            
          )}
               {localStorage.getItem("master-app") ? (
          <Link style={{ textDecoration: 'none' }} to="/cart">
            <div
              style={{
                width: '8vw',
                height: '8vw',
                backgroundColor: '#e5b25d',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                transition: 'transform 0.2s ease-in-out',
                borderRadius: '10px',
                fontSize: '1.5vw'
              }}
              onMouseEnter={handleHover}
              onMouseLeave={(e) => {
                e.target.style.transform = 'translateX(0)';
              }}
            >
              Cart
            </div>
          </Link>):''}
          {localStorage.getItem("master-app") ? (

          <Link style={{ textDecoration: 'None' }} to="/dashboard">
            <div
              style={{
                width: '8vw',
                height: '8vw',
                backgroundColor: '#90D7FF',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                transition: 'transform 0.2s ease-in-out',
                borderRadius: '10px',
                fontSize: '1.5vw'

              }}
              onMouseEnter={handleHover}
              onMouseLeave={(e) => {
                e.target.style.transform = 'translateX(0)';
              }}
            >
              Dashboard
            </div>
          </Link>):''}
          

        </div>
        

      </header>
      <div style={{ padding: '20px' }}>
        <h1>Order History</h1>
        {orders.length > 0 ? (
          orders.map((order) => (
            <div key={order._id} style={{ border: '1px solid #ccc', padding: '10px', marginBottom: '10px' }}>
              <h2>Order ID: {order.orderId}</h2>
              <p>Tracking ID: {order.trackingID}</p>
              <p>Total Items: {order.totalItems}</p>
              <p>Total Quantity: {order.totalQuantity}</p>
              <p>Total Price: ₹{order.totalPrice}</p>

              <p style={{color:'red'}}>Will be delivered between: {sliceAndModifyDate(order.createdAt, 8).toDateString()} and {sliceAndModifyDate(order.createdAt, 10).toDateString()}</p>
              <div>
                <h3>Items:</h3>
                {order.items.map((item) => (
                  <div key={item._id} style={{ display: 'flex', marginBottom: '10px' }}>
                    <img src={`https://myadventurebook.org${item.productId.image[0]}`} alt={item.productId.title} style={{ width: '200px',height:'200px', marginRight: '10px' }} />
                    <div>
                      <h4>{item.productId.title}</h4>
                      <p>Price: ₹{item.productId.price}</p>
                      <p>Quantity: {item.quantity}</p>
                      {item.productId.pictureUpload && (
                        <p style={{ color: 'green' }}>Customisation image uploaded</p>
                      )}
                      {item.productId.namePrint && (
                        <p style={{ color: 'green' }}>Customisation name uploaded</p>
                      )}
                      <button onClick={()=>{openReviewsOverlay();setSelectedProduct(item.productId._id);}} >Add Review</button>

                    </div>
                  </div>
                ))}
              </div>
            </div>
          ))
        ) : (
          <p>No orders found.</p>
        )}
        {showReviewsOverlay && (
        <div style={lightboxStyle} onClick={closeReviewsOverlay}>
          <div style={lightboxContentStyle} onClick={e => e.stopPropagation()}>
            <button style={closeButtonStyle} onClick={closeReviewsOverlay}>&times;</button>
            <form onSubmit={handleReviewSubmit} style={{ marginTop: '20px' }}>
              <h3>Add a Review</h3>
              <div style={{ marginBottom: '10px' }}>
                <label>Rating: </label>
                <select
                  name="rating"
                  value={reviewForm.rating}
                  onChange={handleReviewFormChange}
                  required
                  style={{ padding: '5px', borderRadius: '5px', border: '1px solid #ccc' }}
                >
                  <option value="">Select Rating</option>
                  {[1, 2, 3, 4, 5].map(num => (
                    <option key={num} value={num}>{num} star{num > 1 ? 's' : ''}</option>
                  ))}
                </select>
              </div>
              <div style={{ marginBottom: '10px' }}>
                <label>Review: </label>
                <textarea
                  name="review"
                  value={reviewForm.review}
                  onChange={handleReviewFormChange}
                  required
                  style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', width: '90%', minHeight: '100px' }}
                />
              </div>
              <button
                type="submit"
                style={{
                  padding: '10px',
                  background: '#007bff',
                  color: 'white',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                }}
              >
                Submit Review
              </button>
            </form>
          </div>
        </div>
      )}
      </div>
    </div>
  );
};

export default Dashboard;
