import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import logo from './logo.png'; // Import your image
import axios from 'axios';
import swal from 'sweetalert2';
// import category from '../components/category.css'

const CategoryPage = ({ categoryName }) => {
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showReviewsOverlay, setShowReviewsOverlay] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [reviewForm, setReviewForm] = useState({ rating: '', review: '' });
  const [nameToPrint, setNameToPrint] = useState('');
  const [nameToSize, setNameToSize] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const categoryMap = {
      'Coffee Mugs': 'mug',
      'Key Chains': 'key-chains',
      // 'Notebooks': 'notebooks',
      'Water Bottles': 'waterbottles',
      'T-Shirts': 'T-shirts',
     'Personal Badges': 'personalbadges',
      // 'Comic Books': 'comicbook'
      'Cushions':'Cushions'
    };

    const apiCategory = categoryMap[categoryName];

    fetch(`https://myadventurebook.org/product/productsgets?category=${apiCategory}`)
      .then(response => response.json())
      .then(data => {
        if (data.status && Array.isArray(data.data)) {
          setProducts(data.data);
        } else {
          console.error('Fetched data is not an array:', data);
        }
      })
      .catch(error => console.error('Error fetching products:', error));
  }, [categoryName]);

  const handleHover = (e) => {
    const box = e.target;
    box.style.transform = 'translateX(-5px)';
    setTimeout(() => {
      box.style.transform = 'translateX(5px)';
      setTimeout(() => {
        box.style.transform = 'translateX(-3px)';
        setTimeout(() => {
          box.style.transform = 'translateX(3px)';
          setTimeout(() => {
            box.style.transform = 'translateX(-2px)';
            setTimeout(() => {
              box.style.transform = 'translateX(2px)';
              setTimeout(() => {
                box.style.transform = 'translateX(0)';
              }, 100);
            }, 100);
          }, 100);
        }, 100);
      }, 100);
    }, 100);
  };

  const itemStyle = {
    border: '1px solid #ccc',
    borderRadius: '5px',
    padding: '20px',
    margin: '10px',
    width: '200px',
    textAlign: 'center',
    transition: 'transform 0.3s',
    position: 'relative',
    cursor: 'pointer',
  };

  const imgStyle = {
    width: '100%',
    borderRadius: '5px',
  };

  const listStyle = {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
  };

  const lightboxStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: 'rgba(0, 0, 0, 0.8)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1000,
  };

  const lightboxContentStyle = {
    background: 'white',
    padding: '20px',
    borderRadius: '10px',
    width: '80%',
    position: 'relative',
    maxWidth: '80%',
    maxHeight: '80vh',
    overflow: 'auto'
  };

  const closeButtonStyle = {
    position: 'absolute',
    top: '10px',
    right: '10px',
    background: 'none',
    border: '1px solid black',
    cursor: 'pointer',
    fontSize: '1.5rem',
    zIndex: '99999',
    color:'black'
  };

  const handlelogin=()=>{
    let currentRoute = window.location.pathname;
    console.log(currentRoute);
    localStorage.setItem("loginstatus", JSON.stringify(currentRoute));
    navigate("/login");

  }
  const handleClick = () => {
    localStorage.removeItem("master-app");
    navigate("/");
  };

  const openLightbox = (product) => {
    setSelectedProduct(product);
  };

  const closeLightbox = () => {
    setSelectedProduct(null);
  };

  const openReviewsOverlay = () => {
    setShowReviewsOverlay(true);
  };

  const closeReviewsOverlay = () => {
    setShowReviewsOverlay(false);
  };

  const serverBaseUrl = 'https://myadventurebook.org';

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    
    // Check if a file is selected
    if (file) {
      // Validate file type
      const validImageTypes = ['image/jpeg', 'image/png'];
      if (!validImageTypes.includes(file.type)) {
        alert('Please select a valid image file (jpeg, png).');
        event.target.value = ''; // Clear the input
        return;
      }
  
      // Validate file size (1MB = 1 * 1024 * 1024 bytes)
      if (file.size > 20 * 1024 * 1024) {
        alert('File size should be less than 20MB.');
        event.target.value = ''; // Clear the input
        return;
      }
  
      // Set the selected file only if all conditions are met
      setSelectedFile(file);
    }
  };


  const handleNamePrintChange = (e) => {
    setNameToPrint(e.target.value);
  };
  const handleNameSizeChange = (e) => {
    setNameToSize(e.target.value);
  };


  const handleReviewFormChange = (e) => {
    const { name, value } = e.target;
    setReviewForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };
  const handleAddToCart = (productId,picture,name) => {
    if(picture){
    if (!selectedFile) {
      alert('Please select a valid image file.');
      return;
    }}
    if(name){
    if (!nameToPrint) {
      alert('Please enter a name to print.');
      return;
    }}
  
    addToCart(productId);
  };

  const addToCart = async (productId) => {
    if (!localStorage.getItem('master-app')) {
        swal.fire({
          title: 'Error',
          text: 'You need to be logged in to add to cart.',
          icon: 'error',
        }).then(() => {
          handlelogin()
        });
        return;
      }
    try {
      const storedData = JSON.parse(localStorage.getItem('master-app'));
      const userId = storedData ? storedData.userId : null; // Retrieve user ID from localStorage
            
      const formData = new FormData();
      formData.append('productId', productId);
      formData.append('printName', nameToPrint);
      formData.append('tshirtsizes',nameToSize)
      if (selectedFile) {
        formData.append('images', selectedFile);
      }

      const response = await axios.post(`https://myadventurebook.org/cart/cartcreate/${userId}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });  
      if (response.data.status) {
        swal.fire({
          title: 'Success',
          text: 'Item added to cart successfully!',
          icon: 'success',
        }).then(() => {
          // Delayed refresh after 2 seconds
          setTimeout(() => {
            navigate("/merchandise")
          }, 200);
        });
      } else {
        swal.fire({
          title: 'Error',
          text: 'Failed to add item to cart!',
          icon: 'error',
        });
      }
    } catch (error) {
      console.error('Error adding item to cart:', error);
      swal.fire({
        title: 'Error',
        text: 'An error occurred while adding item to cart.',
        icon: 'error',
      });
    }
  };
  
  const handleReviewSubmit = async (e) => {
    e.preventDefault();
    if (!localStorage.getItem('master-app')) {
        swal.fire({
          title: 'Error',
          text: 'You need to be logged in to submit a review.',
          icon: 'error',
        }).then(() => {
          handlelogin();
        });
        return;
      }
    const userId = JSON.parse(localStorage.getItem('master-app'))?.userId; // Retrieve user ID from localStorage
    const { rating, review } = reviewForm;
  
    const reviewData = {
      reviewedAt: new Date().toISOString(),
      rating: Number(rating),
      review,
    };
  
    console.log('Review Data:', reviewData);
    
    try {
      const response = await axios.post(`https://myadventurebook.org/review/createReview/${selectedProduct._id}/${userId}`, reviewData);
  
      console.log('Response:', response.data);
  
      if (response.data.status) {
        swal.fire({
          title: 'Success',
          text: 'Review added successfully!',
          icon: 'success',
        }).then(() => {
            // Delayed refresh after 2 seconds
            setTimeout(() => {
              window.location.reload();
            }, 200);
          });
        closeReviewsOverlay();
        
      } 
      else if(response.data.message==="duplicate") {
        swal.fire({
          title: 'Error',
          text: 'Already Reviewed',
          icon: 'error',
        });
      }
      else {
        swal.fire({
          title: 'Error',
          text: 'Failed to add review!',
          icon: 'error',
        });
      }
    } catch (error) {
      console.error('Error adding review:', error);
      swal.fire({
        title: 'Error',
        text: "You haven't bought this product, buy this product to add a review.",
        icon: 'error',
      });
    }
   
  };
  const [currentIndex, setCurrentIndex] = useState(0);
  const [thumbnailIndex, setThumbnailIndex] = useState(0);
  const thumbnailsToShow = 4;

  const nextImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === selectedProduct.image.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevImage = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? selectedProduct.image.length - 1 : prevIndex - 1
    );
  };

  const nextThumbnails = () => {
    setThumbnailIndex((prevIndex) =>
      prevIndex + thumbnailsToShow >= selectedProduct.image.length ? prevIndex : prevIndex + thumbnailsToShow
    );
  };

  const prevThumbnails = () => {
    setThumbnailIndex((prevIndex) =>
      prevIndex - thumbnailsToShow < 0 ? 0 : prevIndex - thumbnailsToShow
    );
  };

  const handleThumbnailClick = (index) => {
    setCurrentIndex(index);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const fileInputRef = useRef(null);

  const handleClick1 = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    // Cleanup listener on unmount
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="App" style={{ fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif' }}>
      <header style={{ backgroundColor: 'white', color: '#333', padding: '20px' }}>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px', gap: '1vw' }}>
          <Link style={{ textDecoration: 'None', textAnchor: 'None' }} to="/">
            <div
              style={{
                width: '8vw',
                height: '8vw',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img src={logo} alt="Logo" style={{ maxWidth: '100%', maxHeight: '100%', cursor: 'pointer' }} />
            </div>
          </Link>
          <Link style={{ textDecoration: 'None', textAnchor: 'None' }} to="/merchandise">
            <div
              style={{
                width: '16vw',
                height: '8vw',
                backgroundColor: '#CFF27E',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                transition: 'transform 0.2s ease-in-out',
                borderRadius: '10px',
                fontSize: '1.5vw'
              }}
              onMouseEnter={handleHover}
              onMouseLeave={(e) => {
                e.target.style.transform = 'translateX(0)';
              }}
            >
              Mab Kart
            </div>
          </Link>
          {!localStorage.getItem("master-app") ? (
            <div
            onClick={handlelogin}
             style={{
               width: '8vw',
               height: '8vw',
               backgroundColor: '#ADF7B6', 
               cursor: 'pointer',
               display: 'flex',
               alignItems: 'center',
               justifyContent: 'center',
               transition: 'transform 0.2s ease-in-out',
               borderRadius:'10px',
               fontSize:'1.5vw'
 
             }}
             onMouseEnter={handleHover}
             onMouseLeave={(e) => {
               e.target.style.transform = 'translateX(0)';
             }}
           > 
           Login
           </div>
          ) : (
            <div
              onClick={handleClick}
              style={{
                width: '8vw',
                height: '8vw',
                backgroundColor: '#ADF7B6',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                transition: 'transform 0.2s ease-in-out',
                borderRadius: '10px',
                fontSize: '1.5vw'
              }}
              onMouseEnter={handleHover}
              onMouseLeave={(e) => {
                e.target.style.transform = 'translateX(0)';
              }}
            >
              Logout
            </div>
          )}
               {localStorage.getItem("master-app") ? (
          <Link style={{ textDecoration: 'none' }} to="/cart">
            <div
              style={{
                width: '8vw',
                height: '8vw',
                backgroundColor: '#e5b25d',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                transition: 'transform 0.2s ease-in-out',
                borderRadius: '10px',
                fontSize: '1.5vw'
              }}
              onMouseEnter={(e) => e.target.style.transform = 'scale(1.05)'}
              onMouseLeave={(e) => e.target.style.transform = 'scale(1)'}
            >
              Cart
            </div>
          </Link>):''}
          {localStorage.getItem("master-app") ? (

          <Link style={{ textDecoration: 'None' }} to="/dashboard">
            <div
              style={{
                width: '8vw',
                height: '8vw',
                backgroundColor: '#90D7FF',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                transition: 'transform 0.2s ease-in-out',
                borderRadius: '10px',
                fontSize: '1.5vw'

              }}
              onMouseEnter={handleHover}
              onMouseLeave={(e) => {
                e.target.style.transform = 'translateX(0)';
              }}
            >
              Dashboard
            </div>
          </Link>):''}
        </div>
      </header>
      <h1 style={{ textAlign: 'center' }}>{categoryName} Page</h1>
      <div style={listStyle}>
        {products.map(item => (
          <div
            key={item._id}
            style={itemStyle}
            onMouseOver={e => e.currentTarget.style.transform = 'scale(1.1)'}
            onMouseOut={e => e.currentTarget.style.transform = 'scale(1)'}
            onClick={() => openLightbox(item)}
          >
            <img src={`${serverBaseUrl}${item.image[0]}`} alt={item.title} style={imgStyle} />
            <h3>{item.title}</h3>
            <p style={{ marginBottom: '10px' }}>Price: ₹{item.price}</p>
            <p style={{ marginBottom: '10px' }}>Rating: {item.reviews} stars</p>
          </div>
        ))}
      </div>

      {selectedProduct && (
        <div style={lightboxStyle} onClick={closeLightbox}>
          <div style={lightboxContentStyle} onClick={e => e.stopPropagation()}>
            <button style={closeButtonStyle} onClick={closeLightbox}>&times;</button>
            <div style={{display: windowWidth > 1000 ? 'flex' : 'block',justifyContent:'center',alignItems:'center'}}>
    <div style={{ width:windowWidth > 1000 ? '50%' : '50%',marginBottom:'auto'}}>
      <div style={{}}>
      <div style={{ position: 'relative', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <button
          onClick={prevImage}
          style={{
            position: 'absolute',
            left: '10px',
            backgroundColor: 'transparent',
            fontSize: '2vw',
            cursor: 'pointer',
            zIndex: 1,
            color:'black',
            border:'1px solid black'

          }}
        >
          &#9664;
        </button>
        <img
          src={`${serverBaseUrl}${selectedProduct.image[currentIndex]}`}
          alt={selectedProduct.title}
          style={{ width: '50%', height: '50%', margin: 'auto' }}
        />
        <button
          onClick={nextImage}
          style={{
            position: 'absolute',
            right: '10px',
            backgroundColor: 'transparent',
            fontSize: '2vw',
            cursor: 'pointer',
            zIndex: 1,
            color:'black',
            border:'1px solid black'
          }}
        >
          &#9654;
        </button>
      </div>
      </div>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '20px' }}>
        
        <button
          onClick={prevThumbnails}
          disabled={thumbnailIndex === 0}
          style={{
            backgroundColor: 'transparent',
            fontSize: '1vw',
            cursor: 'pointer',
            zIndex: 1,
            color:'black',
            border:'1px solid black'
          }}
        >
          &#9664;
        </button>
        <div style={{ display: 'flex', overflow: 'hidden', width: '60%' }}>
          {selectedProduct.image.slice(thumbnailIndex, thumbnailIndex + thumbnailsToShow).map((img, index) => (
            <img
              key={index}
              src={`${serverBaseUrl}${img}`}
              alt={`${selectedProduct.title} thumbnail ${index}`}
              onClick={() => handleThumbnailClick(thumbnailIndex + index)}
              style={{
                width: '25%',
                height: '25%',
                margin: '0 5px',
                cursor: 'pointer',
                border: currentIndex === thumbnailIndex + index ? '2px solid blue' : 'none',
              }}
            />
          ))}
        </div>
        <button
          onClick={nextThumbnails}
          disabled={thumbnailIndex + thumbnailsToShow >= selectedProduct.image.length}
          style={{
            backgroundColor: 'transparent',
            fontSize: '1vw',
            cursor: 'pointer',
            zIndex: 1,
            color:'black',
            border:'1px solid black'
          }}
        >
          &#9654;
        </button>
      </div>
    </div>
            <div style={{overflowY:'auto',width:windowWidth > 1000 ? '50%' : '100%',}}>
            <h2>{selectedProduct.title}</h2>
{/* start order detail */}
{selectedProduct.titleStatus && (
             <div style={{  }}>
 
<div style={{  }}>
  {selectedProduct.titleAdd.map((size, index) => (
    <div
      key={index}
      className="size-item"
    >
      {size}
    </div>
  ))}
</div>
  
</div>
            )}
<div style={{ marginBottom: '10px', }}>
  • Usually delivered within 7 days in India<br/>
  • Usually delivered within 15 days outside India<br/>
  • COD Not available<br/>
  • For bulk or corporate purchase contact creativekidsworld777@gmail.com <br/>
  • Delivered worldwide



</div>
{/* bottleDetail
detailsBottle */}
<h3>Product Details</h3>
            <p>{selectedProduct.productDetails}</p>

{/* start bottle */}
{selectedProduct.isAboutItem && (
             <div style={{  }}>
 
<div style={{ marginBottom: '10px' }}>
  {selectedProduct.aboutItem.map((size, index) => (
    <div
      key={index}
      className="size-item"
    >
      {size}
    </div>
  ))}
</div>
  
</div>
            )}
{/* end bottle detail */}


{/* ad care instruction for watebottle */}
{selectedProduct.careInstructionsStatus && (
             <div style={{  }}>
 
<div style={{ marginBottom: '2px' }}>
  <h3>Care Instructions</h3>
  {selectedProduct.careInstructions.map((size, index) => (
    <div
      key={index}
      className="size-item"
    >
      {size}
    </div>
  ))}
</div>
  
</div>
            )}
{/* end */}


<div style={{ marginBottom: '10px', }}>
  <h3>Delivery Information</h3>
  • Since this product is shipped using the services of our courier partners, the date of delivery is an estimate.
  <br/>
  • Your gift may be delivered prior to or after the chosen date of delivery.<br/>
  • A courier product is delivered separately from other hand-delivered products.<br/>
  • No deliveries are made on Sundays and National Holidays.
  <br/>
  • Our courier partners do not call prior to delivering an order, so we recommend that you   <br/> provide an address at which someone will be present to receive the package.  <br/>
  The delivery cannot be redirected to any other address.

  <br/>
  • All courier orders are carefully packed and shipped from our warehouse.
  <br/>
  • Soon after the order is made online, you will receive a tracking number that will help you trace your gift. 

  <br/>
  We will intimate you through call or email when your product is dispatched.

  <br/>




</div>
            <p>
  Price:<span  style={{fontWeight: 'bold',fontSize: '24px', }}> ₹{selectedProduct.price}</span>  <del> {selectedProduct.actualPrice}</del> 
  <span style={{ color: 'darkgreen',fontWeight: 'bold' }}> {selectedProduct.discount}</span>
</p>



            {/* <p>Category: {selectedProduct.category}</p> */}
            {/* <p>Highlights: {selectedProduct.highlights.join(', ')[1]}</p>
            <p>Info: {selectedProduct.info.join(', ')}</p> */}
      
            {/* {selectedProduct.tshirtsize && (
             <div style={{ marginBottom: '10px',alignItems: "center"}}>
  Brand: <span style={{display:" inline-block",alignItems: "center"}}>mimmt kids</span>
</div>
            )}


{selectedProduct.tshirtsize && (
             <div style={{ marginBottom: '10px',alignItems: "center"}}>
  Style Code : <span style={{display:" inline-block",alignItems: "center"}}>mk</span>
</div>
            )}

{selectedProduct.tshirtsize && (
             <div style={{ marginBottom: '10px',alignItems: "center"}}>
  Size: <span style={{display:" inline-block",alignItems: "center"}}>9 to 10 year</span>
</div>
            )}

{selectedProduct.tshirtsize && (
             <div style={{ marginBottom: '10px',alignItems: "center"}}>
  Brand Colour: <span style={{display:" inline-block",alignItems: "center"}}>black 21</span>
</div>
            )}

{selectedProduct.tshirtsize && (
             <div style={{ marginBottom: '10px',alignItems: "center"}}>
  Ideal For: <span style={{display:" inline-block",alignItems: "center"}}>boys</span>
</div>
            )}

{selectedProduct.tshirtsize && (
             <div style={{ marginBottom: '10px',alignItems: "center"}}>
  Febric: <span style={{display:" inline-block",alignItems: "center"}}>pure cotton</span>
</div>
            )}

{selectedProduct.tshirtsize && (
             <div style={{ marginBottom: '10px',alignItems: "center"}}>
  Primary Colour: <span style={{display:" inline-block",alignItems: "center"}}>blavck</span>
</div>
            )} */}
            <p>Rating: {selectedProduct.reviews} stars</p>

            {/* available sizes */}

            {/* {selectedProduct.tshirtsize && (
             <div style={{marginBottom: '10px', fontSize: '20px', fontWeight: 'bold' }}>
   Available Sizes
</div>
            )} */}

            {/* end */}


{/* tshirt sizes */}

{/* {selectedProduct.tshirtsize && (
            <div style={{ marginBottom: '10px', }}>
    S , L , X  ,XL , XXL
</div>
            )} */}
            {/* end */}

{/* enter sizes box */}
{selectedProduct.tshirtsize && (
             <div style={{ backgroundColor: "orange", padding: "5px", border: "1px solid black", width: "fit-content" ,borderRadius: "10%",marginBottom: '10px', display: "flex", justifyContent: "center", alignItems: "center"  }}>
   Select your Size
</div>
            )}

{/* end */}

{/* size upload Box */}
{/* {selectedProduct.tshirtsize && (
  <div style={{ marginBottom: '10px' }}>
    <select
      required
      value={nameToSize}
      onChange={handleNameSizeChange}
      style={{
        padding: '8px',
        border: '1px solid #ccc',
        borderRadius: '5px',
        marginRight: '10px',
        width: '220px',
      }}
    >
      <option value="" disabled>
        Select your Size
      </option>
      <option value="S">S</option>
      <option value="L">L</option>
      <option value="X">X</option>
      <option value="XL">XL</option>
      <option value="XXL">XXL</option>
    </select>
  </div>
)} */}

{selectedProduct.tshirtsize && (
  <div style={{ marginBottom: '10px', display: 'flex', gap: '10px' }}>
    {selectedProduct.sizes.map((size) => (
      <div
        key={size}
        style={{
          padding: '8px',
          border: '1px solid #ccc',
          borderRadius: '5px',
          width: '40px',
          textAlign: 'center',
          cursor: 'pointer',
          backgroundColor: nameToSize === size ? '#ddd' : '#fff',
        }}
        onClick={() => handleNameSizeChange({ target: { value: size } })}
      >
        {size}
      </div>
    ))}
  </div>
)}


{/* end */}


{/* start */}
{(selectedProduct.pictureUpload || selectedProduct.namePrint) && (
    <div style={{marginBottom: '10px', fontSize: '20px', fontWeight: 'bold' }}>
      Personalise Your Product
    </div>
  )}
            
            {/* end */}



{/* start */}
            {selectedProduct.pictureUpload && (
            <div style={{ backgroundColor: "orange", padding: "5px", border: "1px solid black", width: "fit-content" ,borderRadius: "10%",marginBottom: '10px', display: "flex", justifyContent: "center", alignItems: "center"  }}>
    Upload Image
</div>
            )}
{/* end */}

            {selectedProduct.pictureUpload && (
           <div style={{marginBottom:'10px'}}>
           <button type="button" onClick={handleClick1}>
             Choose Image
           </button>
           <input
             type="file"
             accept="image/*"
             ref={fileInputRef}
             onChange={handleFileChange}
             style={{ marginLeft:'5px'}}
             required
           />
         </div>
              )}

              {/* add text visible when its true   start*/}

{selectedProduct.namePrint && (
       <div style={{ backgroundColor: "orange", padding: "5px", border: "1px solid black", width: "fit-content" ,borderRadius: "10%",marginBottom: '10px', display: "flex", justifyContent: "center", alignItems: "center"  }}>
    Add First Name
</div>
)}
{/* end */}

            {selectedProduct.namePrint && (
              <div style={{ marginBottom: '10px' }}>
                <input
                  type="text"
                  maxLength={500}
                  placeholder="Enter your text"
                  required
                  value={nameToPrint}
                  style={{
                    padding: '8px',
                    border: '1px solid #ccc',
                    borderRadius: '5px',
                    marginRight: '10px',
                    width: '200px',
                  }}
                  onChange={handleNamePrintChange}
                />
              </div>
            )}
            <button
              style={{
                padding: '10px',
                background: '#007bff',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
                marginRight: '10px',
              }}
              onClick={openReviewsOverlay}
            >
              Reviews ({selectedProduct.reviews})
            </button>
            <button
              style={{
                padding: '10px',
                background: '#28a745',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                cursor: 'pointer',
              }}
              onClick={() => handleAddToCart(selectedProduct._id,selectedProduct.pictureUpload,selectedProduct.namePrint)}
            >
              Add to Cart
            </button>
            </div>
            </div>
          </div>
        </div>
      )}

      {showReviewsOverlay && (
        <div style={lightboxStyle} onClick={closeReviewsOverlay}>
          <div style={lightboxContentStyle} onClick={e => e.stopPropagation()}>
            <button style={closeButtonStyle} onClick={closeReviewsOverlay}>&times;</button>
            <h2>Product Reviews</h2>
            <div>
              {selectedProduct.reviewIds.map(review => (
                <div key={review._id} style={{ marginBottom: '15px' }}>
                  <h3>{review.reviewedBy}</h3>
                  <p>Rating: {review.rating} stars</p>
                  <p>{review.review}</p>
                </div>
              ))}
            </div>
            <form onSubmit={handleReviewSubmit} style={{ marginTop: '20px' }}>
              <h3>Add a Review</h3>
              <div style={{ marginBottom: '10px' }}>
                <label>Rating: </label>
                <select
                  name="rating"
                  value={reviewForm.rating}
                  onChange={handleReviewFormChange}
                  required
                  style={{ padding: '5px', borderRadius: '5px', border: '1px solid #ccc' }}
                >
                  <option value="">Select Rating</option>
                  {[1, 2, 3, 4, 5].map(num => (
                    <option key={num} value={num}>{num} star{num > 1 ? 's' : ''}</option>
                  ))}
                </select>
              </div>
              <div style={{ marginBottom: '10px' }}>
                <label>Review: </label>
                <textarea
                  name="review"
                  maxLength={500}
                  value={reviewForm.review}
                  onChange={handleReviewFormChange}
                  required
                  style={{ padding: '10px', borderRadius: '5px', border: '1px solid #ccc', width: '90%', minHeight: '100px' }}
                />
              </div>
              <button
                type="submit"
                style={{
                  padding: '10px',
                  background: '#007bff',
                  color: 'white',
                  border: 'none',
                  borderRadius: '5px',
                  cursor: 'pointer',
                }}
              >
                Submit Review
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default CategoryPage;
