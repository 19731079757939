// src/Footer.js
import React from 'react';
import { Link } from 'react-router-dom';
import logo from './logo.png'; // Import your image
import Footer from '../footer'; 

const PrivicyPolicy = () => {
    
    return (
     
        <div>
             
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px',gap:'1vw'}}>
        <Link style={{textDecoration:'None', textAnchor:'None'}} to="/">
          <div
            style={{
              width: '8vw',
              height: '8vw',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img src={logo} alt="Logo" style={{ maxWidth: '100%', maxHeight: '100%', cursor: 'pointer' }} />
          </div>
          </Link>
        </div>
        
          
        
      
        
        

     
<div style={{backgroundColor:'lightblue'}}>
  <h2 style={{marginLeft:'200px',fontSize:'3vw'}}>Privacy Policy for Mab Kart</h2>
      <p style={{marginLeft:'200px',fontSize:'1.5vw'}}>This Privacy Statement has been created in order to demonstrate our firm commitment to your privacy. This document discloses the information gathering and dissemination practices of the Mab Kart section of My Adventure Book website.</p>   
         <p style={{marginLeft:'200px',fontSize:'1.5vw'}}>Information are Not Automatically Logged:</p>
      <p style={{marginLeft:'200px',fontSize:'2vw'}}><b>Information are Not Automatically Logged:</b></p>
      <p style={{marginLeft:'200px',fontSize:'1.5vw'}}>No personal or confidential information is collected automatically. We do not collect your data unless you create an account with us.</p>
<p style={{marginLeft:'200px',fontSize:'2vw'}}><b>Cookies:</b></p>
<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>We do not collect cookies data. Cookies are enabled by most browsers by default. You can disable these in your browsers easily.</p>
<p style={{marginLeft:'200px',fontSize:'2vw'}}><b>Email, SMS communications:</b></p>
<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>When you sign up with us or place orders we will send you updates of the order and shipping details by email or sms. These messages help you with timely information about your order so that you are updated about your purchase. We will occasionally send you emails with information on new offers or products that may be of interest to you. If you do not wish to receive these emails you can unsubscribe at any time by emailing us to stop. We will not spam you with any other advertisements or information. Your email or phone numbers are not shared with third-parties for them to send third-party information or mailers to you.</p>
<p style={{marginLeft:'200px',fontSize:'2vw'}}><b>Member Information:</b></p>
<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>We completely respect your privacy and dislike spam as much as you do. Your name, address, phone number and email addresses collected while joining the website are never revealed to a third-party or marketing agency for promotion of third-party content. You can be assured no spam will reach you because of us.</p>
<p style={{marginLeft:'200px',fontSize:'2vw'}}><b>Instagram / Facebook / Google Login Information:</b></p>
<p style={{marginLeft:'200px',fontSize:'1.56vw'}}>Some personalised products may provide the option of logging in through Facebook, Instagram or Google. This login happens on APIs provided by the respective companies. Data, if any, that is collected is explicitly mentioned as you login. Some data may be used to create the product which you logged in for. No information is permanently stored or shared with third-parties.</p>
<p style={{marginLeft:'200px',fontSize:'2vw'}}><b>External Links:</b></p>
<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>This site may contain links to other sites. We are not responsible for the privacy practices or the content of any websites whose links are provided on this website or those which link to us.</p>
<p style={{marginLeft:'200px',fontSize:'2vw'}}><b>Contacting the Web Site:</b></p>
<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>If you have any questions about this privacy statement, the practices of this site, or your dealings with this Web site, please contact us using the details provided in the website.</p>

          <h2 style={{marginLeft:'200px',fontSize:'3vw'}}>Privacy Policy</h2>
           
            

<h2 style={{marginLeft:'200px',fontSize:'3vw'}}><b>Welcome to the Orion Eduverse Privacy Policy</b></h2>
<p style={{marginLeft:'200px',fontSize:'1.5vw'}}> Effective date: October 04, 2022</p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}><b>Orion Eduverse Pvt Ltd.</b> is the developer of My Adventure Book&mdash;a mobile application that provides a digital edutainment platform for kids. The app empowers kids to star in their own stories and engage in creative activities.</p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>Unless we say otherwise, when we say &ldquo;you&rdquo; or &ldquo;your&rdquo; in this Privacy Policy, we mean both you individually as a parent or guardian, as well as the children with whom you are using the product and Services.</p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>Here, &ldquo;Services&ldquo; refers to our adventures, activities, websites, and any related services or properties we control.</p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>This Privacy Policy is about how <b>Orion Eduverse Private Ltd.</b> will collect, use and share personal information about you. We take the privacy of parents, guardians, and children seriously. Please read this Privacy Policy carefully to learn how we treat your personal information. If you do not agree with this Privacy Policy, you should not use our Product &amp; Services. By using or accessing our Product &amp; Services in any manner, you acknowledge that you accept the practices and policies outlined below, and you hereby consent that we will collect, use and share your information as described in this Privacy Policy.</p>

<p style={{marginLeft:'200px',fontSize:'2vw'}}><b>What this Privacy Policy Covers</b></p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>This Privacy Policy covers how we treat Personal Data that we gather when you access or use our Product &amp; Services. &ldquo;Personal Data&rdquo; means any information that identifies or relates to a particular individual, including a parent, guardian, or child, and also includes information referred to as &ldquo;personally identifiable information&rdquo; or &ldquo;personal information&rdquo; under applicable data privacy laws, rules, or regulations. This Privacy Policy does not cover the practices of companies we don&rsquo;t own or control or people we don&rsquo;t manage.</p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>Accessing our product and services requires parents to create user account using a valid email id and password. We allow up to 2 child accounts per user account. By creating a user account, it implies that parents agree to our privacy policy and terms and conditions. They also consent to child&rsquo;s usage of our services within the framework and rules and regulations as laid down in our privacy policy and terms and conditions.</p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>If we discover that we hold personal data relating to a user under 13 years of age without parental consent,, we will take appropriate measures to ensure that we process that data according to the requirements of applicable laws and regulations or promptly delete the data from our records. If you have reason to believe we hold personal data relating to a user under 13 years of age without parental consent of that user, please contact us.</p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>We do not condition participation in our product &amp; Services on disclosure of more Personal Information from a child under thirteen (13) than is reasonably necessary for that participation, and we do not retain Personal Information from children under thirteen (13) longer than is reasonably necessary in order to fulfill the purpose for which it was disclosed.</p>

<p style={{marginLeft:'200px',fontSize:'2vw'}}><b>Personal Data</b></p>

<p style={{marginLeft:'200px',fontSize:'2vw'}}><b>Categories of Personal Data We Collect</b></p>

<p style={{marginLeft:'200px',fontSize:'1.3vw'}}>The chart in this section details the categories of Personal Data that we may collect:</p>

<p style={{marginLeft:'200px',fontSize:'2vw'}}><b>User/Data type: Parent or Guardian&rsquo;s Profile or Contact Data</b></p>

<p style={{marginLeft:'200px',fontSize:'2vw'}}>Examples of data:</p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>- First name<br />
- Last name<br />
- Password<br />
- Relationship to child<br />
- Email ID</p>

<p style={{marginLeft:'200px',fontSize:'2vw'}}><b>Categories of Third Parties with whom We may share this Personal Data for a Business Purpose:</b></p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>- Service Providers<br />
- Parties You Authorize, Access or Authenticate<br />
- Business Partners</p>

<p style={{marginLeft:'200px',fontSize:'2vw'}}><b>User/Data type: Child&rsquo;s Profile or Contact Data</b></p>

<p style={{marginLeft:'200px',fontSize:'2vw'}}>Examples of data:</p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>- Child&rsquo;s nickname<br />
- Child&rsquo;s date of birth<br />
- Child's grade (optional)</p>

<p style={{marginLeft:'200px',fontSize:'2vw'}}><b>Categories of Third Parties with whom We may share this Personal Data for a Business Purpose:</b></p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>- Service Providers<br />
- Parties You Authorize, Access or Authenticate<br />
- Business Partners</p>

<p style={{marginLeft:'200px',fontSize:'2vw'}}><b>User/Data type: Device data</b></p>

<p style={{marginLeft:'200px',fontSize:'2vw'}}>Examples of data:</p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>- IP Address<br />
- Type of device/operating system/browser used to access the Services</p>

<p style={{marginLeft:'200px',fontSize:'2vw'}}><b>Categories of Third Parties with whom We may share this Personal Data for a Business Purpose:</b></p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>- Service Providers<br />
- Parties You Authorize, Access or Authenticate<br />
- Business Partners</p>

<p style={{marginLeft:'200px',fontSize:'2vw'}}><b>User/Data type: Geo location data</b></p>

<p style={{marginLeft:'200px',fontSize:'2vw'}}>Examples of data:</p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>- IP-address-based location information</p>

<p style={{marginLeft:'200px',fontSize:'2vw'}}><b>Categories of Third Parties with whom We may share this Personal Data for a Business Purpose:</b></p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>- Service Providers<br />
- Parties You Authorize, Access or Authenticate<br />
- Business Partners</p>

<p style={{marginLeft:'200px',fontSize:'2vw'}}><b>User/Data type: Sensory data collected /Sensors used</b></p>

<p style={{marginLeft:'200px',fontSize:'2vw'}}>Examples of data:</p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>- Photo or selfie uploaded</p>

<p style={{marginLeft:'200px',fontSize:'2vw'}}><b>Categories of Third Parties with whom We may share this Personal Data for a Business Purpose:</b></p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>- Service Providers</p>

<p style={{marginLeft:'200px',fontSize:'2vw'}}><b>User/Data type: Inferences drawn from other personal information</b></p>

<p style={{marginLeft:'200px',fontSize:'2vw'}}>Examples of data:</p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>- Profiles reflecting user behavior<br />
- Profiles reflecting user attribute</p>

<p style={{marginLeft:'200px',fontSize:'2vw'}}><b>Categories of Third Parties with whom We may share this Personal Data for a Business Purpose:</b></p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>- Service Providers<br />
- Parties You Authorize, Access or Authenticate<br />
- Business Partners</p>

<p style={{marginLeft:'200px',fontSize:'2vw'}}><b>Sources of Personal Data</b></p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>We collect Personal Data about you from the following categories of sources:</p>

<p style={{marginLeft:'200px',fontSize:'2vw'}}><b>&ldquo;You&rdquo;</b></p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>- When you provide such information directly to us.<br />
- When you create an account or use our Services.<br />
- When you voluntarily provide information in free-form text boxes through the Services or through responses to surveys or questionnaires.<br />
- When you send us an email or otherwise contact us.<br />
- When you use the Services and such information is collected automatically.<br />
- Through Cookies (defined in the &ldquo;Tracking Tools and Opt-Out&rdquo; section below).<br />
- If you download our mobile application we may receive information about your location and mobile device, as applicable.<br />
- If you download and install certain applications and software we make available, we may receive and collect information transmitted from your computing device for the purpose of providing you the relevant Services, such as information regarding when you are logged on and available to receive updates or alert notices.</p>

<p style={{marginLeft:'200px',fontSize:'2vw'}}><b>&ldquo;Third Parties&rdquo;</b></p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>Third parties that provide us with Personal Data about you. Third Parties that share your Personal Data with us include:</p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>- Service Providers. For example, we may use analytics service providers to analyze how you interact and engage with the Services, or third parties may help us provide you with customer support.</p>

<p style={{marginLeft:'200px',fontSize:'2vw'}}><b>Our Commercial or Business Purposes for Collecting Personal Data</b></p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>- Providing, Customizing, and Improving the Services<br />
- Creating and managing your account or other user profiles.<br />
- Processing orders or other transactions; billing.<br />
- Providing you with the products, services, or information you request, including, for example, to provide you and your children with access to activities on our Services.<br />
- Meeting or fulfilling the reason you provided the information to us.<br />
- Providing support and assistance for the Services.<br />
- Improving the Services, including testing, research, internal analytics, and product development.<br />
- Personalizing the Services, website content, and communications based on your preferences.<br />
- Carrying out fraud protection, security, and debugging measures.<br />
- Carrying out other business purposes stated when collecting your Personal Data or as otherwise set forth in applicable data privacy laws, such as the California Consumer Privacy Act (the &ldquo;CCPA&rdquo;).</p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}><b>Corresponding with You</b></p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>- Responding to correspondence that we receive from you, contacting you when necessary or requested or if you leave a review of our Services, and sending you information about us or the Services.<br />
- Sending emails and other communications according to your preferences or that display content that we think will interest you.</p>

<p style={{marginLeft:'200px',fontSize:'2vw'}}><b>Meeting Legal Requirements and Enforcing Legal Terms</b></p>

<p style={{marginLeft:'200px',fontSize:'2vw'}}>- Fulfilling our legal obligations under</p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}> applicable law, regulation, court order or other legal process, such as preventing, detecting and investigating security incidents and potentially illegal or prohibited activities.<br />
- Protecting the rights, property or safety of you, us, or another party.<br />
- Enforcing any agreements with you.<br />
- Responding to claims that any posting or other content violates third-party rights.<br />
- Resolving disputes.</p>

<p style={{marginLeft:'200px',fontSize:'2vw',}}><b>Avatar:</b><br />
For personalized adventure experience we recommend uploading a selfie or image of the user as per the instructions. The image is stored temporarily in our secure server and processed using open source technology (open CV) to generate user avatar set that are used in creating customized adventure for that user. The longevity of avatar set and selfie/image that is uploaded are dependent on the user session. We do not store children&rsquo;s image permanently or share with any 3rd party. Both avatar set and uploaded image/selfie are permanently deleted every time the user completes the adventure or when the system fails to generate the avatar. We will not collect additional categories of Personal Data or use the Personal Data we collected for materially different, unrelated or incompatible purposes without providing you notice.</p>

<p style={{marginLeft:'200px',fontSize:'2vw'}}><b>How We Share Your Personal Data</b></p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>We disclose your Personal Data as indicated in the chart above to the following categories of service providers and other parties:</p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>- <b>Service Providers.</b> These parties help us provide the Services or perform business functions on our behalf. They may include:<br />
 - Security and fraud prevention consultants<br />
 - Hosting and other technology and communications providers<br />
 - Analytics providers<br />
 - Staff augmentation and contract personnel<br />
- <b>Analytics Partners.</b> If you are under thirteen (13), we do not disclose your Personal Data to analytics partners. If you are a parent or guardian, these parties provide analytics on web traffic or usage of the Services. They may include:<br />
 - Companies that track how users found or were referred to the Services<br />
 - Companies that track how users interact with the Services<br />
- <b>Business Partners.</b> These parties may partner with us in offering various services. They include:<br />
 - Businesses that you have a relationship with<br />
 - Companies that we may partner with to offer joint promotional offers or opportunities<br />
- <b>Parties you Authorize, Access or Authenticate.</b> For example:<br />
 - Other users (where you post information publicly or as otherwise necessary to effect a transaction initiated or authorized by you through the Services)<br />
 - Social media services (if you intentionally interact with them through your use of the Services)<br />
 - Third-party business partners who you access through the Services<br />
 - Other parties authorized by you</p>

<p style={{marginLeft:'200px',fontSize:'2vw'}} >In addition, we may share your data in the following ways:</p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>- <b>Legal Obligations.</b> We may share any Personal Data that we collect with third parties in conjunction with any of the activities set forth under &ldquo;Meeting Legal Requirements and Enforcing Legal Terms&rdquo; in the &ldquo;Our Commercial or Business Purposes for Collecting Personal Data&rdquo; section above.<br />
- <b>Business Transfers.</b> All of your Personal Data that we collect may be transferred to a third party if we undergo a merger, acquisition, bankruptcy or other transaction in which that third party assumes control of our business (in whole or in part). Should one of these events occur, we will make reasonable efforts to notify you before your information becomes subject to different privacy and security policies and practices.</p>

<p style={{marginLeft:'200px',fontSize:'2vw'}} ><b>Data that is Not Personal Data</b></p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>We may create aggregated, de-identified or anonymized data from the Personal Data we collect, including by removing information that makes the data personally identifiable to a particular user. We may use such aggregated, de-identified or anonymized data and share it with third parties for our lawful business purposes, including to analyze, build and improve the Services and promote our business, provided that we will not share such data in a manner that could identify you.</p>

<p style={{marginLeft:'200px',fontSize:'2vw'}}><b>Disclosures of De-Identified Information for Research Purposes</b></p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>We may de-identify your Personal Information so that you are not identified as an individual, and provide that information to our academic and not-for-profit research partners.</p>

<p style={{marginLeft:'200px',fontSize:'2vw'}}><b>Tracking Tools and Opt Out</b></p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>Some of our Services may use cookies and similar technologies to enable our servers to recognize your web browser and tell us how and when you visit and use our Services, to analyze trends, learn about our user base and operate and improve our Services. Cookies are small pieces of data&ndash; usually text files &ndash; placed on your computer, tablet, phone or similar device when you use that device to access our Services. We may also supplement the information we collect from you with information received from third parties, including third parties that may have placed their own Cookies on your device(s). Please note that because of our use of Cookies, the Services do not support &ldquo;Do Not Track&rdquo; requests sent from a browser at this time.</p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>Our analytics provider <b>Google Inc.</b> (&ldquo;Google&rdquo;) uses cookies in connection with its Google Analytics services. Google&rsquo;s ability to use and share information collected by Google Analytics about your visits to the Services is subject to the Google Analytics Terms of Use and the Google Privacy Policy. You have the option to opt out of Google&rsquo;s use of Cookies by visiting the Google Analytics Opt-out Browser Add-on at [https://tools.google.com/dlpage/gaoptout/](https://tools.google.com/dlpage/gaoptout/).</p>

<p style={{marginLeft:'200px',fontSize:'2vw'}}><b>Data Security and Retention</b></p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>We seek to protect your Personal Data from unauthorized access, use and disclosure using appropriate physical, technical, organizational and administrative security measures based on the type of Personal Data and how we are processing that data. You should also help protect your data by appropriately selecting and protecting your password and/or other sign-on mechanism; limiting access to your computer or device and browser; and signing off after you have finished accessing your account. Although we work to protect the security of your account and other data that we hold in our records, please be aware that no method of transmitting data over the Internet or storing data is completely secure. We cannot guarantee the complete security of any data you share with us, and except as expressly required by law, we are not responsible for the theft, destruction, loss or inadvertent disclosure of your information or content.</p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>Because our Product and Services are designed to help young children and parents/guardians learn together through family play and independently, we require that you always include the information of at least one child in your account. You cannot add more than 2 child profiles per account. You cannot delete a child profile from your account but you can delete the entire account at any time by accessing your account settings. Please note that in order for us to provide our Services to you, we require that you maintain the information of at least one child in your account at all times.</p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>We retain Personal Data about you for as long as you have an open account with us or as otherwise necessary to provide you with our Services. In some cases, we retain Personal Data for longer, if doing so is necessary to comply with our legal obligations, resolve disputes or collect fees owed, or is otherwise permitted or required by applicable law, rule or regulation. We may further retain information in an anonymous or aggregated form where that information would not identify you personally.</p>

<p style={{marginLeft:'200px',fontSize:'2vw'}}><b>Other State Law Privacy Rights</b></p>

<p style={{marginLeft:'200px',fontSize:'2vw'}}><b>Other California Privacy Rights</b></p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>Under California Civil Code Sections 1798.83-1798.84, California residents are entitled to contact us to prevent disclosure of Personal Data to third parties for such third parties&rsquo; direct marketing purposes; in order to submit such a request, please contact us at [contact@myadventurebook.org](mailto:contact@myadventurebook.org)</p>

<p style={{marginLeft:'200px',fontSize:'2vw'}}><b>Nevada Resident Rights</b></p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>If you are a</p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}> resident of Nevada, you have the right to opt-out of the sale of certain Personal Data to third parties who intend to license or sell that Personal Data. You can exercise this right by contacting us at [contact@myadventurebook.org](mailto:contact@myadventurebook.org) with the subject line &ldquo;Nevada Do Not Sell Request&rdquo; and providing us with your name and the email address associated with your account. Please note that we do not currently sell your Personal Data.</p>

<p style={{marginLeft:'200px',fontSize:'2vw'}}><b>Changes to this Privacy Policy</b></p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>We&rsquo;re constantly trying to improve our Services, so we may need to change this Privacy Policy from time to time, but we will alert you to any such changes by placing a notice on the [www.myadventurebook.org](http://www.myadventurebook.org) website, by sending you an email and/or by some other means. If you use the Services after any changes to the Privacy Policy have been posted, that means you agree to all of the changes. Use of information we collect is subject to the Privacy Policy in effect at the time such information is collected.</p>

<p style={{marginLeft:'200px',fontSize:'2vw'}}><b>How Your Content is Moderated</b></p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>Here at My Adventure Book, we believe in creating a safe space for kids so all the content submitted by our users is carefully reviewed by our moderators. The content our users submit will be assessed by our moderators within 24 hours of being uploaded.</p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>The content curated by users on our app like stories, pictures, questions etc. that meet our criteria will appear in the app along with your user&rsquo;s nickname. Select content may also be featured on our app and social media accounts. If the content does not adhere to our guidelines, then it will be immediately removed and the account will be immediately suspended.</p>

<p style={{marginLeft:'200px',fontSize:'2vw'}}><b>Content Guidelines</b></p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>We have devised certain guidelines to be followed by our users in order to ensure their privacy and that the content uploaded by them is appropriate for our young audience.</p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>Please ensure that the submitted content adheres to the following policies:</p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>- Content must be &ldquo;classroom and age-appropriate&rdquo;<br />
- There should be no use of foul language or curse words in the user-generated content.<br />
- Pictures submitted should not have any inappropriate visuals.<br />
- Ensure that the user-generated content does not contain any abusive, dark, hateful, threatening, or racist topics.<br />
- User nicknames must be appropriate.<br />
- All the user-generated content must be written in English.<br />
- Our review team unfortunately is only proficient in English language. We hope to add more languages in the future.<br />
- Avoid revealing personal information.<br />
 (We care about your child&rsquo;s safety!) In order to keep your child safe please ensure that the content submitted by them or yourself does not contain any personal information about themselves or others. (Personal information includes last names, addresses, contact info, etc)<br />
- No Advertisements<br />
 There should not be advertisements or promotions for other products or apps in your child&rsquo;s content.<br />
- All content must be original.<br />
 All user-generated content like stories, pictures etc posted/uploaded should be original and should not infringe on other users' content. (or third party copyright or trademarks)</p>

<p style={{marginLeft:'200px',fontSize:'2vw'}}><b>Contact Information:</b></p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>If you have any questions or comments about this Privacy Policy, the ways in which we collect and use your Personal Data or your choices and rights regarding such collection and use, please do not hesitate to contact us at:</p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>- +91 9380706694<br />
- [contact@myadventurebook.org](mailto:contact@myadventurebook.org)</p>

<p style={{marginLeft:'200px',fontSize:'1.5vw'}}>Orion Eduverse Private Ltd. C/O SUDARSHAN RAI, GOKUL MARG KANKE ROAD, AMIT NURSERY GALI, KANKE ROAD, ARSANDE, RANCHI, Ranchi, Jharkhand, India, 834006</p>
</div>
<Footer />
        </div>
        
    )
   
    }
   
export default PrivicyPolicy;
