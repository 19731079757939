
import React from 'react';
import logo from './logo.png'; // Import your image
import bg from './bg3.jpg'
import cio from './cio.webp'
import mstart from './microsoft.webp'
import gplay from './google_store.webp'
import astore from './apple_store.webp'
import Footer from './footer.js';
import { useState } from 'react';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Login from './components/login';
import { useNavigate } from "react-router-dom";

function Home() {
  
  const scrollToSection = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }}

  const ImageSlider = () => {
    const images = [
      './dragon.jpg',
      'beaver.jpg',
      'shark.jpg',
      'whale.jpg',
     " abcd.jpg",
      "farm.jpg",
      "spc.png"

    ];
    
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
  
    useEffect(() => {
      const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
      }, 3000); // Change image every 3 seconds (adjust as needed)
    
      return () => clearInterval(interval);
    }, [images.length]);
    
    const handleClickPrev = () => {
      setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
    };
    
    const handleClickNext = () => {
      setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
    };
    
    return (
      <div style={{ width: '95%',margin: '0 auto', position: 'relative',marginTop:'7%'}}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <button style={{ fontSize: '24px', backgroundColor: '#5E17EB', border: 'none', padding: '8px', borderRadius: '5px' ,color:'white'}} onClick={handleClickPrev}>{'<'}</button>
        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
        {[currentImageIndex - 1, currentImageIndex, currentImageIndex + 1].map((index) => (
          <div key={index} style={{ width: '29%',  padding: '8px', textAlign: 'center',justifyContent:'center',display:'flex' }}>
          <img
            src={images[(index + images.length) % images.length]}
            alt={`${index}`}
            style={{
            maxWidth: '100%',
            maxHeight: '100%',
            borderRadius: '5px',
            transform: index === currentImageIndex ? 'scale(1.2)' : 'scale(1)',
            border: index === currentImageIndex ? '3px solid #5E17EB' : 'none'
            }}
          />
          </div>
        ))}
        </div>
        <button style={{ fontSize: '24px', backgroundColor: '#5E17EB', border: 'none', padding: '8px', borderRadius: '5px',color:'white' }} onClick={handleClickNext}>{'>'}</button>
      </div>
      </div>
    );
    };


  const handleHover = (e) => {
    const box = e.target;
    box.style.transform = 'translateX(-5px)';
    setTimeout(() => {
      box.style.transform = 'translateX(5px)';
      setTimeout(() => {
        box.style.transform = 'translateX(-3px)';
        setTimeout(() => {
          box.style.transform = 'translateX(3px)';
          setTimeout(() => {
            box.style.transform = 'translateX(-2px)';
            setTimeout(() => {
              box.style.transform = 'translateX(2px)';
              setTimeout(() => {
                box.style.transform = 'translateX(0)';
              }, 100);
            }, 100);
          }, 100);
        }, 100);
      }, 100);
    }, 100);
  };
  const navigate = useNavigate();
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  const scrollToBottom = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth',
    });
  };

  const handleHover1 = (event) => {
    event.target.style.transform = 'scale(1.05)';
};

const handleHoverOut = (event) => {
    event.target.style.transform = 'scale(1)';
};
  
const boxStyle = {
  width: '21vw',
  height:'40vw',
  borderRadius:'10px',
  padding: '20px',
  border: '1px solid #ccc',
  textAlign: 'center',
  transition: 'transform 0.5s', // Adding transition for smooth effect
};

const imageStyle = {
  marginTop:'-0.8vw',
  maxWidth: '50%',
  height: 'auto',
};

const paraStyle = {
    fontSize: '1.2vw',
  lineHeight: '1.5',
};

const handleClick = () => {
  localStorage.removeItem("master-app");
  navigate("/");
};


  return (
    <div id='home' className="App" style={{ fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif' }}>
      <header style={{ backgroundColor: 'white', color: '#333', padding: '20px' }}>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px',gap:'1vw'}}>
        <Link style={{textDecoration:'None', textAnchor:'None'}} to="/">
          <div
            style={{
              width: '8vw',
              height: '8vw',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img src={logo} alt="Logo" style={{ maxWidth: '100%', maxHeight: '100%', cursor: 'pointer' }} />
          </div>
          </Link>
         <div
            style={{
              width: '8vw',
              height: '8vw',
              backgroundColor: '#FFA270', 
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              transition: 'transform 0.2s ease-in-out',
              borderRadius:'10px',
              fontSize:'1.5vw'
            }}
            onClick={() => scrollToSection('aboutus')}
            onMouseEnter={handleHover}
            onMouseLeave={(e) => {
              e.target.style.transform = 'translateX(0)';
            }}
          >
            About Us
          </div>
          <div
            style={{
              width: '8vw',
              height: '8vw',
              backgroundColor: '#FFD799', 
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              transition: 'transform 0.2s ease-in-out',
              borderRadius:'10px',
              fontSize:'1.5vw'


            }}
            onClick={() => scrollToSection('adventures')}
            onMouseEnter={handleHover}
            onMouseLeave={(e) => {
              e.target.style.transform = 'translateX(0)';
            }}
          >
            Adventure
          </div>
          <div
            style={{
              width: '8vw',
              height: '8vw',
              backgroundColor: '#FFEE93', 
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              transition: 'transform 0.2s ease-in-out',
              borderRadius:'10px',
              fontSize:'1.5vw'

            }}
            onClick={() => scrollToSection('cnr')}
            onMouseEnter={handleHover}
            onMouseLeave={(e) => {
              e.target.style.transform = 'translateX(0)';
            }}
          >
            CNR
          </div>
          <div
            style={{
              width: '8vw',
              height: '8vw',
              backgroundColor: '#FCF5C7', 
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              transition: 'transform 0.2s ease-in-out',
              borderRadius:'10px',
              fontSize:'1.5vw'

            }}
            onClick={() => scrollToSection('faq')}
            onMouseEnter={handleHover}
            onMouseLeave={(e) => {
              e.target.style.transform = 'translateX(0)';
            }}
          >
            FAQ
          </div>
          <div
            style={{
              width: '8vw',
              height: '8vw',
              backgroundColor: '#CEE2D0', 
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              transition: 'transform 0.2s ease-in-out',
              borderRadius:'10px',
              fontSize:'1.5vw'

            }}
            onClick={scrollToBottom}
            onMouseEnter={handleHover}
            onMouseLeave={(e) => {
              e.target.style.transform = 'translateX(0)';
            }}
          >
            Contact
          </div>
              <a 
  style={{ textDecoration: 'None', textAnchor: 'None' }} 
  href="https://mabkart.org" 
  target="_blank" 
  rel="noopener noreferrer"
>
  <div
    style={{
      width: '8vw',
      height: '8vw',
      backgroundColor: '#a8e6cf', 
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: 'transform 0.2s ease-in-out',
      borderRadius: '10px',
      fontSize: '1.5vw'
    }}
    onMouseLeave={(e) => {
      e.target.style.transform = 'translateX(0)';
    }}
  >
    Mab Kart
  </div>
</a> 
          <Link style={{textDecoration:'None', textAnchor:'None'}} to="/PrivicyPolicy">
        
          <div
            style={{
              width: '8vw',
              height: '8vw',
              backgroundColor: '#7cbbcb', 
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              transition: 'transform 0.2s ease-in-out',
              borderRadius:'10px',
              fontSize:'1.5vw'

            }}
           
            onMouseLeave={(e) => {
              e.target.style.transform = 'translateX(0)';
            }}
          >
             Privacy<br/>policy
          </div>
          </Link>

          <a 
  style={{ textDecoration: 'None', textAnchor: 'None' }} 
  href="https://www.creativekidsworld.org/" 
  target="_blank" 
  rel="noopener noreferrer"
>
  <div
    style={{
      width: '8vw',
      height: '8vw',
      backgroundColor: '#7cbbed', 
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      transition: 'transform 0.2s ease-in-out',
      borderRadius: '10px',
      fontSize: '1.5vw'
    }}
    onMouseLeave={(e) => {
      e.target.style.transform = 'translateX(0)';
    }}
  >
    Services
  </div>
</a>


        </div>
        

      </header>
      <div style={{ backgroundColor: '#ffffff', padding: '20px', borderRadius: '20px', margin: '20px auto', maxWidth: '95vw', textAlign: 'center', position: 'relative',marginTop:'-0%' }}>
  <img src={bg} alt="Background" style={{ width: '90vw', borderRadius: '20px'}} />
  <div style={{ left:'6.6%',position: 'absolute', top: '15%', width: '40vw', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <img src={mstart} alt="Microsoft" style={{maxWidth: '16vw', maxHeight: '16vw', margin: '0 10px'}} />
      <a href="https://drive.google.com/file/d/1hEWSYOT3Yurr7pI5-vwkSCwQxa9dlwg8/view"><img src={cio} alt="CIO" style={{maxWidth: '16vw', maxHeight: '16vw', margin: '0 10px', borderRadius:'10px' }} /></a>
  </div>
  <div style={{ position: 'absolute', top: '34%', width: '40vw',alignItems: 'center', justifyContent: 'center', fontFamily: 'Comic Sans MS',left:'6.6%'}}>
    <h1 style={{fontSize:'3vw', color: '#5E17EB'}}>WELCOME TO MY ADVENTURE BOOK</h1>
    <h2 style={{fontSize:'2vw',fontWeight:'lighter',marginTop:'-4%'}}>Where Kids learn from Adventures!</h2>
  </div>
  <div style={{ left:'6.6%',position: 'absolute',marginTop:'-10%', width: '40vw',alignItems: 'center', justifyContent: 'center',display:'flex'}}>
  <a target="_blank" href="https://play.google.com/store/apps/details?id=com.mab.my_adventure_book&pli=1"><img src={gplay} alt="android" style={{ maxWidth: '18vw', maxHeight: '18vw', margin: '0 5px', borderRadius:'10px' }} /></a>
  <a target="_blank" href="https://apps.apple.com/in/app/mab-my-adventure-book/id1640209316"><img src={astore} alt="ios" style={{ maxWidth: '18vw', maxHeight: '18vw', margin: '0 5px', borderRadius:'10px' }} /></a>

</div>
</div>



      {/* Content goes here */}
      <div id='adventures'>
      <ImageSlider />

      </div>

      <div style={{ display: 'flex', justifyContent: 'center', margin: '20px',marginTop:'7%', }}>
            <div style={{
                width: '80vw',
            }}>
                <div style={{
                    position: 'relative',
                    paddingBottom: '56.25%', // Aspect ratio: 16:9
                    paddingTop: '30px',
                    height: 0,
                    
                }}>
                    <iframe 
                        
                        width="100%" 
                        height="100%" 
                        src="https://www.youtube.com/embed/YRtvV8CXObk?autoplay=1&loop=1" 
                        title="YouTube video player" 
                        frameBorder="0" 
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
                        allowFullScreen
                        style={{
                            position: 'absolute',
                            borderRadius:'30px',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                        }}
                    ></iframe>
                </div>
            </div>
        </div>

        <div id='aboutus'>
          <div style={{textAlign:'center'}}>
            <h1>
              About us
            </h1>
            <h2 style={{fontWeight:'lighter'}}>My Adventure Book is a holistic learning platform where kids star in their own adventures.<br/>They go on exciting fun-filled journeys while learning important and interesting lessons that they can apply in their schools and day-to-day lives.</h2>
          </div>
          <div style={{ justifyContent: 'center', alignItems: 'center' }}>
            <div style={{ marginRight: '20px',display:'flex',textAlign:'left'}}>
                <img src="Jack.png" alt="Left Image" style={{ maxWidth: '30vw', height: '25vw' }} />
                <div>
                <h2 style={{marginTop:'7vw',fontSize:'2vw',color:'purple'}}>Jack</h2>
                <h3 style={{fontSize:'1.5vw',width:'40vw',color:'indigo'}}>Jack is a bubbly, active, and enthusiastic elephant. He loves science, and space and has a great memory for absorbing knowledge. If you ever had any questions, Jack would know the answer.</h3>
                </div>
               </div>
            <div style={{ display: 'flex', justifyContent: 'center',gap:'2vw' }}>
                <div style={{ 
                    width: '19vw',
                    height: '25vw',
                    borderRadius: '5vw',
                    textAlign: 'center',
                    border:'1px solid black'                }}>
                    <img src="value_1.png" alt="Image 1" style={{ 
                        maxWidth: '100%',
                        maxHeight: '100%',
                        borderRadius: '10px 10px 0 0', // Round the top corners
                    }} />
                    <p style={{marginTop:'-8vw',fontSize:'1.8vw'}}>Go on wonderful adventures: immerse yourself in the story</p>
                </div>
                <div style={{ 
                    width: '19vw',
                    height: '25vw',
                    borderRadius: '5vw',
                    textAlign: 'center',
                    border:'1px solid black'                   }}>
                    <img src="value_2.png" alt="Image 2" style={{ 
                        maxWidth: '100%',
                        maxHeight: '100%',
                        borderRadius: '10px 10px 0 0', // Round the top corners
                    }} />
                    <p style={{marginTop:'-8vw',fontSize:'1.8vw'}}>Be the hero of your adventure</p>
                </div>
                <div style={{ 
                    width: '19vw',
                    height: '25vw',
                    borderRadius: '5vw',
                    textAlign: 'center',
                    border:'1px solid black'                   }}>
                    <img src="value_3.png" alt="Image 3" style={{ 
                        maxWidth: '100%',
                        maxHeight: '100%',
                        borderRadius: '10px 10px 0 0', // Round the top corners
                    }} />
                    <p style={{marginTop:'-8vw',fontSize:'1.8vw',maxWidth:'90%',marginLeft:'5%'}}>Select cool suits for our avatar</p>
                </div>
                <div style={{ 
                    width: '19vw',
                    height: '25vw',
                    borderRadius: '5vw',
                    textAlign: 'center',
                    border:'1px solid black'                   }}>
                    <img src="value_4.png" alt="Image 4" style={{ 
                        maxWidth: '100%',
                        maxHeight: '100%',
                        borderRadius: '10px 10px 0 0', // Round the top corners
                    }} />
                    <p style={{marginTop:'-8vw',fontSize:'1.8vw'}}>Have unlimited fun & learning with activities</p>
                </div>
            </div>
            <div style={{display:'flex', marginLeft: '20px',textAlign:'right' ,justifyContent:'right'}}>
                <div>
                <h2 style={{marginTop:'7vw',fontSize:'2vw',color:'red'}}>Rose</h2>
                <h3 style={{fontSize:'1.5vw',width:'40vw',color:'brown'}}>A sassy, and witty feline, Rose is always up to some mischief. She is curious and creative, tiny yet fierce.</h3>
                </div>
                <img src="rose.png" alt="Right Image" style={{ maxWidth: '30vw', height: '25vw' }} />

            </div>
        </div>
        </div>

        <div style={{ display: 'flex', justifyContent: 'center'}}>
            <div style={{ marginRight: '1.5vw', textAlign: 'center',display:'flex' }}>
                <h2 style={{ writingMode: 'vertical-lr',marginRight:'0.8vw',marginLeft:'-1%', backgroundColor:'lightgreen',fontSize:'1.8vw'}}>Learning Outcomes</h2>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    
                        <div
                            style={{
                                width: '30vw',
                                height: '5vw',
                                borderRadius: '20px',
                                margin: '10px',
                                backgroundColor: 'lightgreen',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                transition: 'transform 0.3s', // Adding transition for smooth effect
                            }}
                            onMouseEnter={handleHover}
                            onMouseLeave={handleHoverOut}
                        >
                            <p style={{fontSize:'1.8vw'}}>Music & Dance</p>
                        </div>
                        <div
                            style={{
                              width: '30vw',
                              height: '5vw',
                                borderRadius: '20px',
                                margin: '10px',
                                backgroundColor: 'lightgreen',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                transition: 'transform 0.3s', // Adding transition for smooth effect
                            }}
                            onMouseEnter={handleHover}
                            onMouseLeave={handleHoverOut}
                        >
                            <p style={{fontSize:'1.8vw'}}>Self Expression</p>
                        </div> <div
                            style={{
                              width: '30vw',
                              height: '5vw',
                                borderRadius: '20px',
                                margin: '10px',
                                backgroundColor: 'lightgreen',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                transition: 'transform 0.3s', // Adding transition for smooth effect
                            }}
                            onMouseEnter={handleHover}
                            onMouseLeave={handleHoverOut}
                        >
                            <p style={{fontSize:'1.8vw'}}>Exploration</p>
                        </div> <div
                            style={{
                              width: '30vw',
                              height: '5vw',
                                borderRadius: '20px',
                                margin: '10px',
                                backgroundColor: 'lightgreen',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                transition: 'transform 0.3s', // Adding transition for smooth effect
                            }}
                            onMouseEnter={handleHover}
                            onMouseLeave={handleHoverOut}
                        >
                            <p style={{fontSize:'1.8vw'}}>Maths, Science, English </p>
                        </div> <div
                            style={{
                              width: '30vw',
                              height: '5vw',
                                borderRadius: '20px',
                                margin: '10px',
                                backgroundColor: 'lightgreen',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                transition: 'transform 0.3s', // Adding transition for smooth effect
                            }}
                            onMouseEnter={handleHover}
                            onMouseLeave={handleHoverOut}
                        >
                            <p style={{fontSize:'1.8vw'}}>Social & Emotional Intelligence </p>
                        </div> <div
                            style={{
                              width: '30vw',
                              height: '5vw',
                                borderRadius: '20px',
                                margin: '10px',
                                backgroundColor: 'lightgreen',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                transition: 'transform 0.3s', // Adding transition for smooth effect
                            }}
                            onMouseEnter={handleHover}
                            onMouseLeave={handleHoverOut}
                        >
                            <p style={{fontSize:'1.8vw'}}>Creativity & Curiosity </p>
                        </div>
                </div>
            </div>

            <div style={{ marginLeft: '1.5vw', textAlign: 'center',display:'flex' }}>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <div
                            style={{
                              width: '30vw',
                              height: '5vw',
                                borderRadius: '20px',
                                margin: '10px',
                                backgroundColor: 'skyblue',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                transition: 'transform 0.3s', // Adding transition for smooth effect
                            }}
                            onMouseEnter={handleHover}
                            onMouseLeave={handleHoverOut}
                        >
                            <p style={{fontSize:'1.8vw'}}>Parent-Child Bonding</p>
                        </div>
                        <div
                            style={{
                              width: '30vw',
                              height: '5vw',
                                borderRadius: '20px',
                                margin: '10px',
                                backgroundColor: 'skyblue',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                transition: 'transform 0.3s', // Adding transition for smooth effect
                            }}
                            onMouseEnter={handleHover}
                            onMouseLeave={handleHoverOut}
                        >
                            <p style={{fontSize:'1.8vw'}}>Most Meaningful Screentime</p>
                        </div>
                        <div
                            style={{
                              width: '30vw',
                                height: '5vw',
                                borderRadius: '20px',
                                margin: '10px',
                                backgroundColor: 'skyblue',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                transition: 'transform 0.3s', // Adding transition for smooth effect
                            }}
                            onMouseEnter={handleHover}
                            onMouseLeave={handleHoverOut}
                        >
                            <p style={{fontSize:'1.8vw'}}>Progress Tracking</p>
                        </div>
                        <div
                            style={{
                              width: '30vw',
                              height: '5vw',
                                borderRadius: '20px',
                                margin: '10px',
                                backgroundColor: 'skyblue',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                transition: 'transform 0.3s', // Adding transition for smooth effect
                            }}
                            onMouseEnter={handleHover}
                            onMouseLeave={handleHoverOut}
                        >
                            <p style={{fontSize:'1.8vw'}}>High Quality Interactive Content</p>
                        </div>
                        <div
                            style={{
                              width: '30vw',
                              height: '5vw',
                                borderRadius: '20px',
                                margin: '10px',
                                backgroundColor: 'skyblue',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                transition: 'transform 0.3s', // Adding transition for smooth effect
                            }}
                            onMouseEnter={handleHover}
                            onMouseLeave={handleHoverOut}
                        >
                            <p style={{fontSize:'1.8vw'}}>Regular Content</p>
                        </div>
                        <div
                            style={{
                              width: '30vw',
                              height: '5vw',
                                borderRadius: '20px',
                                margin: '10px',
                                backgroundColor: 'skyblue',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                transition: 'transform 0.3s', // Adding transition for smooth effect
                            }}
                            onMouseEnter={handleHover}
                            onMouseLeave={handleHoverOut}
                        >
                            <p style={{fontSize:'1.8vw'}}>Safe & Ad-free</p>
                        </div>
                    
                </div>
                <h2 style={{ writingMode: 'vertical-lr',marginLeft:'0.8vw',marginRight:'-1%',backgroundColor:'skyblue',fontSize:'1.8vw' }}>What We Offer</h2>

            </div>
        </div>
      
      <div id='cnr' style={{ display: 'flex', justifyContent: 'center',gap:'2%' ,marginTop:'5vw'}}>
            
             <div>
              <h2 style={{textAlign:'center',fontSize:'2vw',color:'purple'}}>What our Expert says</h2>
            <div 
                style={boxStyle} 
                onMouseEnter={handleHover1} 
                onMouseLeave={handleHoverOut}
            >
                <img src="anuradha.png" alt="Image 1" style={imageStyle} />

                <p style={paraStyle}>"For children, everything is a wonder and when we allow them to satisfy their curiosity through safe, self initiated and playful exploration, learning occurs naturally. My Adventure Book is a beautiful attempt to help children learn through context based task and activities that are purposeful,meaningful,socially significant and enjoyable"</p>
                <h3  style={{textAlign:'center',fontSize:'1.5vw',color:'purple'}}>Dr. Anuradha<br/> Pediatrician Parenting coach and Adolescent specialist, 25+years exp.</h3>
            </div></div>
            <div>
              <h2 style={{textAlign:'center',fontSize:'2vw',color:'magenta'}}>Child Safety</h2>
            <div 
                style={boxStyle} 
                onMouseEnter={handleHover1} 
                onMouseLeave={handleHoverOut}
            >
                <img src="safety.png" alt="Image 1" style={ {marginTop:'-0.8vw', maxWidth: '70%',  height: 'auto',}} />

                <p style={paraStyle}>My Adventure Book makes the online safety of you and your children a top priority. We only collect data in the interest of the user, to create the most personalized educational and fun experience possible. We will never sell your data to third parties and our platform is ad-free.</p>
                <h3  style={{textAlign:'center',fontSize:'1.5vw',color:'magenta'}}>We commit to a responsible and ethical use of data.</h3>
            </div></div>
            <div>
              <h2 style={{textAlign:'center',fontSize:'2vw',color:'green'}}>Plant a Tree</h2>
            <div 
                style={boxStyle} 
                onMouseEnter={handleHover1} 
                onMouseLeave={handleHoverOut}
            >
                <img src="plant_tree.png" alt="Image 1" style={imageStyle} />

                <p style={paraStyle}>
                As part of our  Corporate Nature Responsibility (CNR), we will be planting a total of 1111 trees. Please follow us on social media for further updates. If you would like to support this initiative in your own capacity, do write to us at contact@myadventurebook.org                  </p>
                  <h3  style={{textAlign:'center',fontSize:'1.5vw',color:'green'}}>Corporate Nature Responsibility</h3>
            </div></div>
        </div>
        


      <div id='faq' style={{ textAlign: 'center' ,marginBottom:'5%'}}>
      <h2 style={{ fontSize: '3vw', margin: '20px 0',fontWeight:'bold',marginBottom:'5%',marginTop:'5%',color:'orange' }}>Frequently Asked Questions</h2>
      
      {/* FAQ divisions */}
      <div style={{ maxWidth: '75vw', margin: '0 auto'}}>

        <div style={{ display: 'flex' }}>
          <div style={{ width: '40%', textAlign: 'left' }}>
            <h3 style={{ fontSize: '1.5vw' }}>
            What is My Adventure Book?              
            </h3>
          </div>
          <div style={{width:'5%'}}></div>
          <div style={{ width: '55%', textAlign: 'left' }}>
            <p style={{fontSize: '1.5vw' }}>
            My Adventure Book is a holistic learning platform where kids star in their own adventures. 

They go on exciting fun-filled journeys while learning important & interesting lessons that they can apply to 

their schools and day-to-day lives.                </p>
          </div>
        </div>
        <hr style={{ width: '100%', borderWidth: '1.5px', borderColor: 'rgba(0, 0, 0, 0.1)' }} />
        
        
        <div style={{ display: 'flex' }}>
          <div style={{ width: '40%', textAlign: 'left' }}>
            <h3 style={{ fontSize: '1.5vw' }}>
            What are the benefits of My Adventure Book?             </h3>
          </div>
          <div style={{width:'5%'}}></div>
          <div style={{ width: '55%', textAlign: 'left' }}>
            <p style={{fontSize: '1.5vw' }}>
            My Adventure book provides a perfect blend of learning and entertainment to young learners. They can revise 

maths concepts, improve their reading skills, sharpen their artistic skills, build socio-emotional skills, write and share creative 

content and much more.</p>
          </div>
        </div>
        <hr style={{ width: '100%', borderWidth: '1.5px', borderColor: 'rgba(0, 0, 0, 0.1)' }} />
        

        <div style={{ display: 'flex' }}>
          <div style={{ width: '40%', textAlign: 'left' }}>
            <h3 style={{ fontSize: '1.5vw' }}>
            Is My Adventure Book for a certain age?            </h3>
          </div>
          <div style={{width:'5%'}}></div>
          <div style={{ width: '55%', textAlign: 'left' }}>
            <p style={{fontSize: '1.5vw' }}>
            Anyone with a love for adventures would enjoy My Adventure Book but it’s especially great 

for kids aged 3+ years.</p>
          </div>
        </div>
        {/* <hr style={{ width: '100%', borderWidth: '1.5px', borderColor: 'rgba(0, 0, 0, 0.1)' }} /> */}
        

        {/* <div style={{ display: 'flex' }}>
          <div style={{ width: '40%', textAlign: 'left' }}>
            <h3 style={{ fontSize: '1.5vw' }}>
            Where can I download My Adventure Book app?             </h3>
          </div>
          <div style={{width:'5%'}}></div>
          <div style={{ width: '55%', textAlign: 'left' }}>
            <p style={{fontSize: '1.5vw' }}>
            Users can get early access to the beta release of My Adventure Book by registering their email id in this <a href='https://forms.gle/ypT3BFm4ujdWaNGa6'>Link</a>.
            <br/>Once they are registered they will receive an email with the download link. After downloading the app, users can create an account. </p>
          </div>
        </div> */}
        <hr style={{ width: '100%', borderWidth: '1.5px', borderColor: 'rgba(0, 0, 0, 0.1)' }} />
        

        <div style={{ display: 'flex' }}>
          <div style={{ width: '40%', textAlign: 'left' }}>
            <h3 style={{ fontSize: '1.5vw' }}>
            Can I use My Adventure Book on multiple devices?             </h3>
          </div>
          <div style={{width:'5%'}}></div>
          <div style={{ width: '55%', textAlign: 'left' }}>
            <p style={{fontSize: '1.5vw' }}>
            Yes. After creating an account on My Adventure book you can use your registered email id and password to log into any of your devices.<br/>

The current set of devices and Operating systems it supports are as follows:<br/>

Android: Version 10.0 and above<br/>

iOS: Version 11.0 and above </p>
          </div>
        </div>
        <hr style={{ width: '100%', borderWidth: '1.5px', borderColor: 'rgba(0, 0, 0, 0.1)' }} />
        

        <div style={{ display: 'flex' }}>
          <div style={{ width: '40%', textAlign: 'left' }}>
            <h3 style={{ fontSize: '1.5vw' }}>
            How do I create an account in the My Adventure Book app?            </h3>
          </div>
          <div style={{width:'5%'}}></div>
          <div style={{ width: '55%', textAlign: 'left' }}>
            <p style={{fontSize: '1.5vw' }}>
            To create an account, follow these steps::<br/>
            Step 1: Download My Adventure Book app from the Google Play store or Appstore <br/>

            Step 2: After installing the app, open the app and click on the ‘Create Free Account’ button.<br/>

            Step 3: Enter your name, email and password and click create account. <br/>

            Step 4: Go to your email and Verify your account and your registration process is complete.</p>
          </div>
        </div>
        <hr style={{ width: '100%', borderWidth: '1.5px', borderColor: 'rgba(0, 0, 0, 0.1)' }} />
        

        <div style={{ display: 'flex' }}>
          <div style={{ width: '40%', textAlign: 'left' }}>
            <h3 style={{ fontSize: '1.5vw' }}>
            How do I verify my email?            </h3>
          </div>
          <div style={{width:'5%'}}></div>
          <div style={{ width: '55%', textAlign: 'left' }}>
            <p style={{fontSize: '1.5vw' }}>
            When signing up for a new account on the My Adventure Book app, you'll need to verify your email address. To verify your email, check your email account, check in your INBOX or SPAM folder for a message that says "Your Registration successful" in the subject line, and then click on the button that says "Verify". You will receive a success message as “ Verified”. You can then start using My Adventure Book app.

</p>
          </div>
        </div>
        <hr style={{ width: '100%', borderWidth: '1.5px', borderColor: 'rgba(0, 0, 0, 0.1)' }} />
        

        <div style={{ display: 'flex' }}>
          <div style={{ width: '40%', textAlign: 'left' }}>
            <h3 style={{ fontSize: '1.5vw' }}>
            How do I create my child's profile?
</h3>
          </div>
          <div style={{width:'5%'}}></div>
          <div style={{ width: '55%', textAlign: 'left' }}>
            <p style={{fontSize: '1.5vw' }}>
            To add your child’s profile, go to Profile selection page and press “Add child” button  and  input your child’s name, age, relationship to the child and select profile picture. Click ‘Okay’ and your child’s profile will be added.  </p>
          </div>
        </div>
        <hr style={{ width: '100%', borderWidth: '1.5px', borderColor: 'rgba(0, 0, 0, 0.1)' }} />
        

        <div style={{ display: 'flex' }}>
          <div style={{ width: '40%', textAlign: 'left' }}>
            <h3 style={{ fontSize: '1.5vw' }}>
            How many child accounts can we have per user account?            </h3>
          </div>
          <div style={{width:'5%'}}></div>
          <div style={{ width: '55%', textAlign: 'left' }}>
            <p style={{fontSize: '1.5vw' }}>
            My Adventure Book allows each user profile to create upto a maximum of 2 child profiles in the app. </p>
          </div>
        </div>
        <hr style={{ width: '100%', borderWidth: '1.5px', borderColor: 'rgba(0, 0, 0, 0.1)' }} />
        

        <div style={{ display: 'flex' }}>
          <div style={{ width: '40%', textAlign: 'left' }}>
            <h3 style={{ fontSize: '1.5vw' }}>
            Do I need internet access to use My Adventure Book?            </h3>
          </div>
          <div style={{width:'5%'}}></div>
          <div style={{ width: '55%', textAlign: 'left' }}>
            <p style={{fontSize: '1.5vw' }}>
            Yes, My Adventure Book can only be used while connected to a WiFi network or a cellular network.

</p>
          </div>
        </div>
        <hr style={{ width: '100%', borderWidth: '1.5px', borderColor: 'rgba(0, 0, 0, 0.1)' }} />
        

        <div style={{ display: 'flex' }}>
          <div style={{ width: '40%', textAlign: 'left' }}>
            <h3 style={{ fontSize: '1.5vw' }}>
            Why is my story locked?            </h3>
          </div>
          <div style={{width:'5%'}}></div>
          <div style={{ width: '55%', textAlign: 'left' }}>
            <p style={{fontSize: '1.5vw' }}>
            If your story is locked or not playing you need to create your user avatar first. In order to create your avatar, click a selfie or upload a picture as explained in the “Steps” section. After this, select the avatar suit of your choice and click done. Once your user avatar is created the story will be automatically unlocked. 
</p>
          </div>
        </div>
        <hr style={{ width: '100%', borderWidth: '1.5px', borderColor: 'rgba(0, 0, 0, 0.1)' }} />
        

        <div style={{ display: 'flex' }}>
          <div style={{ width: '40%', textAlign: 'left' }}>
            <h3 style={{ fontSize: '1.5vw' }}>
            Does My Adventure Book contain ads?            </h3>
          </div>
          <div style={{width:'5%'}}></div>
          <div style={{ width: '55%', textAlign: 'left' }}>
            <p style={{fontSize: '1.5vw' }}>
            No! My Adventure Book is 100% ad free. </p>
          </div>
        </div>
        <hr style={{ width: '100%', borderWidth: '1.5px', borderColor: 'rgba(0, 0, 0, 0.1)' }} />
        

   
      </div>
    </div>
              
      <Footer />
      <div style={{ position: 'fixed', bottom: '20px', right: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', zIndex: '999' }}>
        <div id='contact'
          style={{
            width: '50px',
            height: '50px',
            backgroundColor: '#5E17EB',
            borderRadius: '50%',
            marginBottom: '10px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
          onClick={scrollToTop}
        >
          <span style={{ color: 'white', fontSize: '24px', fontWeight: 'bold' }}>↑</span>
        </div>
        <div
          style={{
            width: '50px',
            height: '50px',
            backgroundColor: '#5E17EB',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
          }}
          onClick={scrollToBottom}
        >
          <span style={{ color: 'white', fontSize: '24px', fontWeight: 'bold' }}>↓</span>
        </div>
      </div>
    </div>
  );
}

export default Home;



// import React from 'react';
// import logo from './logo.png'; // Import your image
// import bg from './bg3.jpg'
// import cio from './cio.webp'
// import mstart from './microsoft.webp'
// import gplay from './google_store.webp'
// import astore from './apple_store.webp'
// import Footer from './footer.js';
// import { useState } from 'react';
// import { useEffect } from 'react';
// import { Link } from 'react-router-dom';
// import Login from './components/login';
// import { useNavigate } from "react-router-dom";
// import footer from './footer.js'
// function Home() {
  
//   const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
//   const [showTermsOfService, setShowTermsOfService] = useState(false);

//   const openPrivacyPolicy = () => {
//     setShowPrivacyPolicy(true);
//   };

//   const closePrivacyPolicy = () => {
//     setShowPrivacyPolicy(false);
//   };
//   const scrollToSection = (sectionId) => {
//     const section = document.getElementById(sectionId);
//     if (section) {
//       section.scrollIntoView({ behavior: 'smooth' });
//     }}

//   const ImageSlider = () => {
//     const images = [
//       './dragon.jpg',
//       'beaver.jpg',
//       'shark.jpg',
//       'whale.jpg',
//      " abcd.jpg",
//       "farm.jpg"

//     ];
    
//     const [currentImageIndex, setCurrentImageIndex] = useState(0);
  
//     useEffect(() => {
//       const interval = setInterval(() => {
//       setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
//       }, 3000); // Change image every 3 seconds (adjust as needed)
    
//       return () => clearInterval(interval);
//     }, [images.length]);
    
//     const handleClickPrev = () => {
//       setCurrentImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
//     };
    
//     const handleClickNext = () => {
//       setCurrentImageIndex((prevIndex) => (prevIndex + 1) % images.length);
//     };
    
//     return (
//       <div style={{ width: '95%',margin: '0 auto', position: 'relative',marginTop:'7%'}}>
//       <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//         <button style={{ fontSize: '24px', backgroundColor: '#5E17EB', border: 'none', padding: '8px', borderRadius: '5px' ,color:'white'}} onClick={handleClickPrev}>{'<'}</button>
//         <div style={{ width: '100%', display: 'flex', justifyContent: 'space-around' }}>
//         {[currentImageIndex - 1, currentImageIndex, currentImageIndex + 1].map((index) => (
//           <div key={index} style={{ width: '29%',  padding: '8px', textAlign: 'center',justifyContent:'center',display:'flex' }}>
//           <img
//             src={images[(index + images.length) % images.length]}
//             alt={`${index}`}
//             style={{
//             maxWidth: '100%',
//             maxHeight: '100%',
//             borderRadius: '5px',
//             transform: index === currentImageIndex ? 'scale(1.2)' : 'scale(1)',
//             border: index === currentImageIndex ? '3px solid #5E17EB' : 'none'
//             }}
//           />
//           </div>
//         ))}
//         </div>
//         <button style={{ fontSize: '24px', backgroundColor: '#5E17EB', border: 'none', padding: '8px', borderRadius: '5px',color:'white' }} onClick={handleClickNext}>{'>'}</button>
//       </div>
//       </div>
//     );
//     };


//   const handleHover = (e) => {
//     const box = e.target;
//     box.style.transform = 'translateX(-5px)';
//     setTimeout(() => {
//       box.style.transform = 'translateX(5px)';
//       setTimeout(() => {
//         box.style.transform = 'translateX(-3px)';
//         setTimeout(() => {
//           box.style.transform = 'translateX(3px)';
//           setTimeout(() => {
//             box.style.transform = 'translateX(-2px)';
//             setTimeout(() => {
//               box.style.transform = 'translateX(2px)';
//               setTimeout(() => {
//                 box.style.transform = 'translateX(0)';
//               }, 100);
//             }, 100);
//           }, 100);
//         }, 100);
//       }, 100);
//     }, 100);
//   };
//   const navigate = useNavigate();
//   const scrollToTop = () => {
//     window.scrollTo({
//       top: 0,
//       behavior: 'smooth',
//     });
//   };

//   const scrollToBottom = () => {
//     window.scrollTo({
//       top: document.body.scrollHeight,
//       behavior: 'smooth',
//     });
//   };

//   const handleHover1 = (event) => {
//     event.target.style.transform = 'scale(1.05)';
// };

// const handleHoverOut = (event) => {
//     event.target.style.transform = 'scale(1)';
// };
  
// const boxStyle = {
//   width: '21vw',
//   height:'40vw',
//   borderRadius:'10px',
//   padding: '20px',
//   border: '1px solid #ccc',
//   textAlign: 'center',
//   transition: 'transform 0.5s', // Adding transition for smooth effect
// };

// const imageStyle = {
//   marginTop:'-0.8vw',
//   maxWidth: '50%',
//   height: 'auto',
// };

// const paraStyle = {
//     fontSize: '1.2vw',
//   lineHeight: '1.5',
// };

// const handleClick = () => {
//   localStorage.removeItem("master-app");
//   navigate("/");
// };


//   return (
//     <div id='home' className="App" style={{ fontFamily: '-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif' }}>
//       <header style={{ backgroundColor: 'white', color: '#333', padding: '20px' }}>
//         <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px',gap:'1vw'}}>
//         <Link style={{textDecoration:'None', textAnchor:'None'}} to="/">
//           <div
//             style={{
//               width: '8vw',
//               height: '8vw',
//               display: 'flex',
//               alignItems: 'center',
//               justifyContent: 'center',
//             }}
//           >
//             <img src={logo} alt="Logo" style={{ maxWidth: '100%', maxHeight: '100%', cursor: 'pointer' }} />
//           </div>
//           </Link>
//          <div
//             style={{
//               width: '8vw',
//               height: '8vw',
//               backgroundColor: '#FFA270', 
//               cursor: 'pointer',
//               display: 'flex',
//               alignItems: 'center',
//               justifyContent: 'center',
//               transition: 'transform 0.2s ease-in-out',
//               borderRadius:'10px',
//               fontSize:'1.5vw'
//             }}
//             onClick={() => scrollToSection('aboutus')}
//             onMouseEnter={handleHover}
//             onMouseLeave={(e) => {
//               e.target.style.transform = 'translateX(0)';
//             }}
//           >
//             About Us
//           </div>
//           <div
//             style={{
//               width: '8vw',
//               height: '8vw',
//               backgroundColor: '#FFD799', 
//               cursor: 'pointer',
//               display: 'flex',
//               alignItems: 'center',
//               justifyContent: 'center',
//               transition: 'transform 0.2s ease-in-out',
//               borderRadius:'10px',
//               fontSize:'1.5vw'


//             }}
//             onClick={() => scrollToSection('adventures')}
//             onMouseEnter={handleHover}
//             onMouseLeave={(e) => {
//               e.target.style.transform = 'translateX(0)';
//             }}
//           >
//             Adventure
//           </div>
//           <div
//             style={{
//               width: '8vw',
//               height: '8vw',
//               backgroundColor: '#FFEE93', 
//               cursor: 'pointer',
//               display: 'flex',
//               alignItems: 'center',
//               justifyContent: 'center',
//               transition: 'transform 0.2s ease-in-out',
//               borderRadius:'10px',
//               fontSize:'1.5vw'

//             }}
//             onClick={() => scrollToSection('cnr')}
//             onMouseEnter={handleHover}
//             onMouseLeave={(e) => {
//               e.target.style.transform = 'translateX(0)';
//             }}
//           >
//             CNR
//           </div>
//           <div
//             style={{
//               width: '8vw',
//               height: '8vw',
//               backgroundColor: '#FCF5C7', 
//               cursor: 'pointer',
//               display: 'flex',
//               alignItems: 'center',
//               justifyContent: 'center',
//               transition: 'transform 0.2s ease-in-out',
//               borderRadius:'10px',
//               fontSize:'1.5vw'

//             }}
//             onClick={() => scrollToSection('faq')}
//             onMouseEnter={handleHover}
//             onMouseLeave={(e) => {
//               e.target.style.transform = 'translateX(0)';
//             }}
//           >
//             FAQ
//           </div>
//           <div
//             style={{
//               width: '8vw',
//               height: '8vw',
//               backgroundColor: '#CEE2D0', 
//               cursor: 'pointer',
//               display: 'flex',
//               alignItems: 'center',
//               justifyContent: 'center',
//               transition: 'transform 0.2s ease-in-out',
//               borderRadius:'10px',
//               fontSize:'1.5vw'

//             }}
//             onClick={scrollToBottom}
//             onMouseEnter={handleHover}
//             onMouseLeave={(e) => {
//               e.target.style.transform = 'translateX(0)';
//             }}
//           >
//             Contact
//           </div>
//           <Link style={{textDecoration:'None', textAnchor:'None'}} to="/merchandise">
//           {/* <div
//             style={{
//               width: '8vw',
//               height: '8vw',
//               backgroundColor: '#7cbbcb', 
//               cursor: 'pointer',
//               display: 'flex',
//               alignItems: 'center',
//               justifyContent: 'center',
//               transition: 'transform 0.2s ease-in-out',
//               borderRadius:'10px',
//               fontSize:'1.5vw'

//             }}
//             onMouseEnter={handleHover}
//             onMouseLeave={(e) => {
//               e.target.style.transform = 'translateX(0)';
//             }}
//           > 
//           Merch
//           </div> */}
//           </Link>

        
//           <div
//             style={{
//               width: '8vw',
//               height: '8vw',
//               backgroundColor: '#7cbbcb', 
//               cursor: 'pointer',
//               display: 'flex',
//               alignItems: 'center',
//               justifyContent: 'center',
//               transition: 'transform 0.2s ease-in-out',
//               borderRadius:'10px',
//               fontSize:'1.5vw',
             

//             }}
//             onClick={openPrivacyPolicy}
//             onMouseEnter={handleHover}
//             onMouseLeave={(e) => {
//               e.target.style.transform = 'translateX(0)';
//             }}
//           >
//              Privacy<br/>policy
//           </div>

//         </div>
        

//       </header>

//       {/* stat */}

//       {showPrivacyPolicy && (
//         <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={closePrivacyPolicy}>

//           <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '5px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)', overflowY: 'auto', maxHeight: '80vh', maxWidth: '80vw' ,textAlign:'left'}}>
//           <button onClick={closePrivacyPolicy}>Close</button>

//             <h2>Privacy Policy</h2>
//             <p>
//             <p>Effective date: October 04, 2022</p>

// <p><b>Welcome to the Orion Eduverse Privacy Policy</b></p>

// <p><b>Orion Eduverse Pvt Ltd.</b> is the developer of My Adventure Book&mdash;a mobile application that provides a digital edutainment platform for kids. The app empowers kids to star in their own stories and engage in creative activities.</p>

// <p>Unless we say otherwise, when we say &ldquo;you&rdquo; or &ldquo;your&rdquo; in this Privacy Policy, we mean both you individually as a parent or guardian, as well as the children with whom you are using the product and Services.</p>

// <p>Here, &ldquo;Services&ldquo; refers to our adventures, activities, websites, and any related services or properties we control.</p>

// <p>This Privacy Policy is about how <b>Orion Eduverse Private Ltd.</b> will collect, use and share personal information about you. We take the privacy of parents, guardians, and children seriously. Please read this Privacy Policy carefully to learn how we treat your personal information. If you do not agree with this Privacy Policy, you should not use our Product &amp; Services. By using or accessing our Product &amp; Services in any manner, you acknowledge that you accept the practices and policies outlined below, and you hereby consent that we will collect, use and share your information as described in this Privacy Policy.</p>

// <p><b>What this Privacy Policy Covers</b></p>

// <p>This Privacy Policy covers how we treat Personal Data that we gather when you access or use our Product &amp; Services. &ldquo;Personal Data&rdquo; means any information that identifies or relates to a particular individual, including a parent, guardian, or child, and also includes information referred to as &ldquo;personally identifiable information&rdquo; or &ldquo;personal information&rdquo; under applicable data privacy laws, rules, or regulations. This Privacy Policy does not cover the practices of companies we don&rsquo;t own or control or people we don&rsquo;t manage.</p>

// <p>Accessing our product and services requires parents to create user account using a valid email id and password. We allow up to 2 child accounts per user account. By creating a user account, it implies that parents agree to our privacy policy and terms and conditions. They also consent to child&rsquo;s usage of our services within the framework and rules and regulations as laid down in our privacy policy and terms and conditions.</p>

// <p>If we discover that we hold personal data relating to a user under 13 years of age without parental consent,, we will take appropriate measures to ensure that we process that data according to the requirements of applicable laws and regulations or promptly delete the data from our records. If you have reason to believe we hold personal data relating to a user under 13 years of age without parental consent of that user, please contact us.</p>

// <p>We do not condition participation in our product &amp; Services on disclosure of more Personal Information from a child under thirteen (13) than is reasonably necessary for that participation, and we do not retain Personal Information from children under thirteen (13) longer than is reasonably necessary in order to fulfill the purpose for which it was disclosed.</p>

// <p><b>Personal Data</b></p>

// <p><b>Categories of Personal Data We Collect</b></p>

// <p>The chart in this section details the categories of Personal Data that we may collect:</p>

// <p><b>User/Data type: Parent or Guardian&rsquo;s Profile or Contact Data</b></p>

// <p>Examples of data:</p>

// <p>- First name<br />
// - Last name<br />
// - Password<br />
// - Relationship to child<br />
// - Email ID</p>

// <p><b>Categories of Third Parties with whom We may share this Personal Data for a Business Purpose:</b></p>

// <p>- Service Providers<br />
// - Parties You Authorize, Access or Authenticate<br />
// - Business Partners</p>

// <p><b>User/Data type: Child&rsquo;s Profile or Contact Data</b></p>

// <p>Examples of data:</p>

// <p>- Child&rsquo;s nickname<br />
// - Child&rsquo;s date of birth<br />
// - Child's grade (optional)</p>

// <p><b>Categories of Third Parties with whom We may share this Personal Data for a Business Purpose:</b></p>

// <p>- Service Providers<br />
// - Parties You Authorize, Access or Authenticate<br />
// - Business Partners</p>

// <p><b>User/Data type: Device data</b></p>

// <p>Examples of data:</p>

// <p>- IP Address<br />
// - Type of device/operating system/browser used to access the Services</p>

// <p><b>Categories of Third Parties with whom We may share this Personal Data for a Business Purpose:</b></p>

// <p>- Service Providers<br />
// - Parties You Authorize, Access or Authenticate<br />
// - Business Partners</p>

// <p><b>User/Data type: Geo location data</b></p>

// <p>Examples of data:</p>

// <p>- IP-address-based location information</p>

// <p><b>Categories of Third Parties with whom We may share this Personal Data for a Business Purpose:</b></p>

// <p>- Service Providers<br />
// - Parties You Authorize, Access or Authenticate<br />
// - Business Partners</p>

// <p><b>User/Data type: Sensory data collected /Sensors used</b></p>

// <p>Examples of data:</p>

// <p>- Photo or selfie uploaded</p>

// <p><b>Categories of Third Parties with whom We may share this Personal Data for a Business Purpose:</b></p>

// <p>- Service Providers</p>

// <p><b>User/Data type: Inferences drawn from other personal information</b></p>

// <p>Examples of data:</p>

// <p>- Profiles reflecting user behavior<br />
// - Profiles reflecting user attribute</p>

// <p><b>Categories of Third Parties with whom We may share this Personal Data for a Business Purpose:</b></p>

// <p>- Service Providers<br />
// - Parties You Authorize, Access or Authenticate<br />
// - Business Partners</p>

// <p><b>Sources of Personal Data</b></p>

// <p>We collect Personal Data about you from the following categories of sources:</p>

// <p><b>&ldquo;You&rdquo;</b></p>

// <p>- When you provide such information directly to us.<br />
// - When you create an account or use our Services.<br />
// - When you voluntarily provide information in free-form text boxes through the Services or through responses to surveys or questionnaires.<br />
// - When you send us an email or otherwise contact us.<br />
// - When you use the Services and such information is collected automatically.<br />
// - Through Cookies (defined in the &ldquo;Tracking Tools and Opt-Out&rdquo; section below).<br />
// - If you download our mobile application we may receive information about your location and mobile device, as applicable.<br />
// - If you download and install certain applications and software we make available, we may receive and collect information transmitted from your computing device for the purpose of providing you the relevant Services, such as information regarding when you are logged on and available to receive updates or alert notices.</p>

// <p><b>&ldquo;Third Parties&rdquo;</b></p>

// <p>Third parties that provide us with Personal Data about you. Third Parties that share your Personal Data with us include:</p>

// <p>- Service Providers. For example, we may use analytics service providers to analyze how you interact and engage with the Services, or third parties may help us provide you with customer support.</p>

// <p><b>Our Commercial or Business Purposes for Collecting Personal Data</b></p>

// <p>- Providing, Customizing, and Improving the Services<br />
// - Creating and managing your account or other user profiles.<br />
// - Processing orders or other transactions; billing.<br />
// - Providing you with the products, services, or information you request, including, for example, to provide you and your children with access to activities on our Services.<br />
// - Meeting or fulfilling the reason you provided the information to us.<br />
// - Providing support and assistance for the Services.<br />
// - Improving the Services, including testing, research, internal analytics, and product development.<br />
// - Personalizing the Services, website content, and communications based on your preferences.<br />
// - Carrying out fraud protection, security, and debugging measures.<br />
// - Carrying out other business purposes stated when collecting your Personal Data or as otherwise set forth in applicable data privacy laws, such as the California Consumer Privacy Act (the &ldquo;CCPA&rdquo;).</p>

// <p><b>Corresponding with You</b></p>

// <p>- Responding to correspondence that we receive from you, contacting you when necessary or requested or if you leave a review of our Services, and sending you information about us or the Services.<br />
// - Sending emails and other communications according to your preferences or that display content that we think will interest you.</p>

// <p><b>Meeting Legal Requirements and Enforcing Legal Terms</b></p>

// <p>- Fulfilling our legal obligations under</p>

// <p> applicable law, regulation, court order or other legal process, such as preventing, detecting and investigating security incidents and potentially illegal or prohibited activities.<br />
// - Protecting the rights, property or safety of you, us, or another party.<br />
// - Enforcing any agreements with you.<br />
// - Responding to claims that any posting or other content violates third-party rights.<br />
// - Resolving disputes.</p>

// <p><b>Avatar:</b><br />
// For personalized adventure experience we recommend uploading a selfie or image of the user as per the instructions. The image is stored temporarily in our secure server and processed using open source technology (open CV) to generate user avatar set that are used in creating customized adventure for that user. The longevity of avatar set and selfie/image that is uploaded are dependent on the user session. We do not store children&rsquo;s image permanently or share with any 3rd party. Both avatar set and uploaded image/selfie are permanently deleted every time the user completes the adventure or when the system fails to generate the avatar. We will not collect additional categories of Personal Data or use the Personal Data we collected for materially different, unrelated or incompatible purposes without providing you notice.</p>

// <p><b>How We Share Your Personal Data</b></p>

// <p>We disclose your Personal Data as indicated in the chart above to the following categories of service providers and other parties:</p>

// <p>- <b>Service Providers.</b> These parties help us provide the Services or perform business functions on our behalf. They may include:<br />
//  - Security and fraud prevention consultants<br />
//  - Hosting and other technology and communications providers<br />
//  - Analytics providers<br />
//  - Staff augmentation and contract personnel<br />
// - <b>Analytics Partners.</b> If you are under thirteen (13), we do not disclose your Personal Data to analytics partners. If you are a parent or guardian, these parties provide analytics on web traffic or usage of the Services. They may include:<br />
//  - Companies that track how users found or were referred to the Services<br />
//  - Companies that track how users interact with the Services<br />
// - <b>Business Partners.</b> These parties may partner with us in offering various services. They include:<br />
//  - Businesses that you have a relationship with<br />
//  - Companies that we may partner with to offer joint promotional offers or opportunities<br />
// - <b>Parties you Authorize, Access or Authenticate.</b> For example:<br />
//  - Other users (where you post information publicly or as otherwise necessary to effect a transaction initiated or authorized by you through the Services)<br />
//  - Social media services (if you intentionally interact with them through your use of the Services)<br />
//  - Third-party business partners who you access through the Services<br />
//  - Other parties authorized by you</p>

// <p>In addition, we may share your data in the following ways:</p>

// <p>- <b>Legal Obligations.</b> We may share any Personal Data that we collect with third parties in conjunction with any of the activities set forth under &ldquo;Meeting Legal Requirements and Enforcing Legal Terms&rdquo; in the &ldquo;Our Commercial or Business Purposes for Collecting Personal Data&rdquo; section above.<br />
// - <b>Business Transfers.</b> All of your Personal Data that we collect may be transferred to a third party if we undergo a merger, acquisition, bankruptcy or other transaction in which that third party assumes control of our business (in whole or in part). Should one of these events occur, we will make reasonable efforts to notify you before your information becomes subject to different privacy and security policies and practices.</p>

// <p><b>Data that is Not Personal Data</b></p>

// <p>We may create aggregated, de-identified or anonymized data from the Personal Data we collect, including by removing information that makes the data personally identifiable to a particular user. We may use such aggregated, de-identified or anonymized data and share it with third parties for our lawful business purposes, including to analyze, build and improve the Services and promote our business, provided that we will not share such data in a manner that could identify you.</p>

// <p><b>Disclosures of De-Identified Information for Research Purposes</b></p>

// <p>We may de-identify your Personal Information so that you are not identified as an individual, and provide that information to our academic and not-for-profit research partners.</p>

// <p><b>Tracking Tools and Opt Out</b></p>

// <p>Some of our Services may use cookies and similar technologies to enable our servers to recognize your web browser and tell us how and when you visit and use our Services, to analyze trends, learn about our user base and operate and improve our Services. Cookies are small pieces of data&ndash; usually text files &ndash; placed on your computer, tablet, phone or similar device when you use that device to access our Services. We may also supplement the information we collect from you with information received from third parties, including third parties that may have placed their own Cookies on your device(s). Please note that because of our use of Cookies, the Services do not support &ldquo;Do Not Track&rdquo; requests sent from a browser at this time.</p>

// <p>Our analytics provider <b>Google Inc.</b> (&ldquo;Google&rdquo;) uses cookies in connection with its Google Analytics services. Google&rsquo;s ability to use and share information collected by Google Analytics about your visits to the Services is subject to the Google Analytics Terms of Use and the Google Privacy Policy. You have the option to opt out of Google&rsquo;s use of Cookies by visiting the Google Analytics Opt-out Browser Add-on at [https://tools.google.com/dlpage/gaoptout/](https://tools.google.com/dlpage/gaoptout/).</p>

// <p><b>Data Security and Retention</b></p>

// <p>We seek to protect your Personal Data from unauthorized access, use and disclosure using appropriate physical, technical, organizational and administrative security measures based on the type of Personal Data and how we are processing that data. You should also help protect your data by appropriately selecting and protecting your password and/or other sign-on mechanism; limiting access to your computer or device and browser; and signing off after you have finished accessing your account. Although we work to protect the security of your account and other data that we hold in our records, please be aware that no method of transmitting data over the Internet or storing data is completely secure. We cannot guarantee the complete security of any data you share with us, and except as expressly required by law, we are not responsible for the theft, destruction, loss or inadvertent disclosure of your information or content.</p>

// <p>Because our Product and Services are designed to help young children and parents/guardians learn together through family play and independently, we require that you always include the information of at least one child in your account. You cannot add more than 2 child profiles per account. You cannot delete a child profile from your account but you can delete the entire account at any time by accessing your account settings. Please note that in order for us to provide our Services to you, we require that you maintain the information of at least one child in your account at all times.</p>

// <p>We retain Personal Data about you for as long as you have an open account with us or as otherwise necessary to provide you with our Services. In some cases, we retain Personal Data for longer, if doing so is necessary to comply with our legal obligations, resolve disputes or collect fees owed, or is otherwise permitted or required by applicable law, rule or regulation. We may further retain information in an anonymous or aggregated form where that information would not identify you personally.</p>

// <p><b>Other State Law Privacy Rights</b></p>

// <p><b>Other California Privacy Rights</b></p>

// <p>Under California Civil Code Sections 1798.83-1798.84, California residents are entitled to contact us to prevent disclosure of Personal Data to third parties for such third parties&rsquo; direct marketing purposes; in order to submit such a request, please contact us at [contact@myadventurebook.org](mailto:contact@myadventurebook.org)</p>

// <p><b>Nevada Resident Rights</b></p>

// <p>If you are a</p>

// <p> resident of Nevada, you have the right to opt-out of the sale of certain Personal Data to third parties who intend to license or sell that Personal Data. You can exercise this right by contacting us at [contact@myadventurebook.org](mailto:contact@myadventurebook.org) with the subject line &ldquo;Nevada Do Not Sell Request&rdquo; and providing us with your name and the email address associated with your account. Please note that we do not currently sell your Personal Data.</p>

// <p><b>Changes to this Privacy Policy</b></p>

// <p>We&rsquo;re constantly trying to improve our Services, so we may need to change this Privacy Policy from time to time, but we will alert you to any such changes by placing a notice on the [www.myadventurebook.org](http://www.myadventurebook.org) website, by sending you an email and/or by some other means. If you use the Services after any changes to the Privacy Policy have been posted, that means you agree to all of the changes. Use of information we collect is subject to the Privacy Policy in effect at the time such information is collected.</p>

// <p><b>How Your Content is Moderated</b></p>

// <p>Here at My Adventure Book, we believe in creating a safe space for kids so all the content submitted by our users is carefully reviewed by our moderators. The content our users submit will be assessed by our moderators within 24 hours of being uploaded.</p>

// <p>The content curated by users on our app like stories, pictures, questions etc. that meet our criteria will appear in the app along with your user&rsquo;s nickname. Select content may also be featured on our app and social media accounts. If the content does not adhere to our guidelines, then it will be immediately removed and the account will be immediately suspended.</p>

// <p><b>Content Guidelines</b></p>

// <p>We have devised certain guidelines to be followed by our users in order to ensure their privacy and that the content uploaded by them is appropriate for our young audience.</p>

// <p>Please ensure that the submitted content adheres to the following policies:</p>

// <p>- Content must be &ldquo;classroom and age-appropriate&rdquo;<br />
// - There should be no use of foul language or curse words in the user-generated content.<br />
// - Pictures submitted should not have any inappropriate visuals.<br />
// - Ensure that the user-generated content does not contain any abusive, dark, hateful, threatening, or racist topics.<br />
// - User nicknames must be appropriate.<br />
// - All the user-generated content must be written in English.<br />
// - Our review team unfortunately is only proficient in English language. We hope to add more languages in the future.<br />
// - Avoid revealing personal information.<br />
//  (We care about your child&rsquo;s safety!) In order to keep your child safe please ensure that the content submitted by them or yourself does not contain any personal information about themselves or others. (Personal information includes last names, addresses, contact info, etc)<br />
// - No Advertisements<br />
//  There should not be advertisements or promotions for other products or apps in your child&rsquo;s content.<br />
// - All content must be original.<br />
//  All user-generated content like stories, pictures etc posted/uploaded should be original and should not infringe on other users' content. (or third party copyright or trademarks)</p>

// <p><b>Contact Information:</b></p>

// <p>If you have any questions or comments about this Privacy Policy, the ways in which we collect and use your Personal Data or your choices and rights regarding such collection and use, please do not hesitate to contact us at:</p>

// <p>- +91 9380706694<br />
// - [contact@myadventurebook.org](mailto:contact@myadventurebook.org)</p>

// <p>Orion Eduverse Private Ltd. C/O SUDARSHAN RAI, GOKUL MARG KANKE ROAD, AMIT NURSERY GALI, KANKE ROAD, ARSANDE, RANCHI, Ranchi, Jharkhand, India, 834006</p>




//             </p>
//             <button onClick={closePrivacyPolicy}>Close</button>
//           </div>
//         </div>
//       )}
// {/* end */}

//       <div style={{ backgroundColor: '#ffffff', padding: '20px', borderRadius: '20px', margin: '20px auto', maxWidth: '95vw', textAlign: 'center', position: 'relative',marginTop:'-0%' }}>
//   <img src={bg} alt="Background" style={{ width: '90vw', borderRadius: '20px'}} />
//   <div style={{ left:'6.6%',position: 'absolute', top: '15%', width: '40vw', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
//         <img src={mstart} alt="Microsoft" style={{maxWidth: '16vw', maxHeight: '16vw', margin: '0 10px'}} />
//       <a href="https://drive.google.com/file/d/1hEWSYOT3Yurr7pI5-vwkSCwQxa9dlwg8/view"><img src={cio} alt="CIO" style={{maxWidth: '16vw', maxHeight: '16vw', margin: '0 10px', borderRadius:'10px' }} /></a>
//   </div>
//   <div style={{ position: 'absolute', top: '34%', width: '40vw',alignItems: 'center', justifyContent: 'center', fontFamily: 'Comic Sans MS',left:'6.6%'}}>
//     <h1 style={{fontSize:'3vw', color: '#5E17EB'}}>WELCOME TO MY ADVENTURE BOOK</h1>
//     <h2 style={{fontSize:'2vw',fontWeight:'lighter',marginTop:'-4%'}}>Where Kids learn from Adventures!</h2>
//   </div>
//   <div style={{ left:'6.6%',position: 'absolute',marginTop:'-10%', width: '40vw',alignItems: 'center', justifyContent: 'center',display:'flex'}}>
//   <a target="_blank" href="https://play.google.com/store/apps/details?id=com.mab.my_adventure_book&pli=1"><img src={gplay} alt="android" style={{ maxWidth: '18vw', maxHeight: '18vw', margin: '0 5px', borderRadius:'10px' }} /></a>
//   <a target="_blank" href="https://apps.apple.com/in/app/mab-my-adventure-book/id1640209316"><img src={astore} alt="ios" style={{ maxWidth: '18vw', maxHeight: '18vw', margin: '0 5px', borderRadius:'10px' }} /></a>

// </div>
// </div>



//       {/* Content goes here */}
//       <div id='adventures'>
//       <ImageSlider />

//       </div>

//       <div style={{ display: 'flex', justifyContent: 'center', margin: '20px',marginTop:'7%', }}>
//             <div style={{
//                 width: '80vw',
//             }}>
//                 <div style={{
//                     position: 'relative',
//                     paddingBottom: '56.25%', // Aspect ratio: 16:9
//                     paddingTop: '30px',
//                     height: 0,
                    
//                 }}>
//                     <iframe 
                        
//                         width="100%" 
//                         height="100%" 
//                         src="https://www.youtube.com/embed/YRtvV8CXObk?autoplay=1&loop=1" 
//                         title="YouTube video player" 
//                         frameBorder="0" 
//                         allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
//                         allowFullScreen
//                         style={{
//                             position: 'absolute',
//                             borderRadius:'30px',
//                             top: 0,
//                             left: 0,
//                             width: '100%',
//                             height: '100%',
//                         }}
//                     ></iframe>
//                 </div>
//             </div>
//         </div>

//         <div id='aboutus'>
//           <div style={{textAlign:'center'}}>
//             <h1>
//               About us
//             </h1>
//             <h2 style={{fontWeight:'lighter'}}>My Adventure Book is a holistic learning platform where kids star in their own adventures.<br/>They go on exciting fun-filled journeys while learning important and interesting lessons that they can apply in their schools and day-to-day lives.</h2>
//           </div>
//           <div style={{ justifyContent: 'center', alignItems: 'center' }}>
//             <div style={{ marginRight: '20px',display:'flex',textAlign:'left'}}>
//                 <img src="Jack.png" alt="Left Image" style={{ maxWidth: '30vw', height: '25vw' }} />
//                 <div>
//                 <h2 style={{marginTop:'7vw',fontSize:'2vw',color:'purple'}}>Jack</h2>
//                 <h3 style={{fontSize:'1.5vw',width:'40vw',color:'indigo'}}>Jack is a bubbly, active, and enthusiastic elephant. He loves science, and space and has a great memory for absorbing knowledge. If you ever had any questions, Jack would know the answer.</h3>
//                 </div>
//                </div>
//             <div style={{ display: 'flex', justifyContent: 'center',gap:'2vw' }}>
//                 <div style={{ 
//                     width: '19vw',
//                     height: '25vw',
//                     borderRadius: '5vw',
//                     textAlign: 'center',
//                     border:'1px solid black'                }}>
//                     <img src="value_1.png" alt="Image 1" style={{ 
//                         maxWidth: '100%',
//                         maxHeight: '100%',
//                         borderRadius: '10px 10px 0 0', // Round the top corners
//                     }} />
//                     <p style={{marginTop:'-8vw',fontSize:'1.8vw'}}>Go on wonderful adventures: immerse yourself in the story</p>
//                 </div>
//                 <div style={{ 
//                     width: '19vw',
//                     height: '25vw',
//                     borderRadius: '5vw',
//                     textAlign: 'center',
//                     border:'1px solid black'                   }}>
//                     <img src="value_2.png" alt="Image 2" style={{ 
//                         maxWidth: '100%',
//                         maxHeight: '100%',
//                         borderRadius: '10px 10px 0 0', // Round the top corners
//                     }} />
//                     <p style={{marginTop:'-8vw',fontSize:'1.8vw'}}>Be the hero of your adventure</p>
//                 </div>
//                 <div style={{ 
//                     width: '19vw',
//                     height: '25vw',
//                     borderRadius: '5vw',
//                     textAlign: 'center',
//                     border:'1px solid black'                   }}>
//                     <img src="value_3.png" alt="Image 3" style={{ 
//                         maxWidth: '100%',
//                         maxHeight: '100%',
//                         borderRadius: '10px 10px 0 0', // Round the top corners
//                     }} />
//                     <p style={{marginTop:'-8vw',fontSize:'1.8vw',maxWidth:'90%',marginLeft:'5%'}}>Select cool suits for our avatar</p>
//                 </div>
//                 <div style={{ 
//                     width: '19vw',
//                     height: '25vw',
//                     borderRadius: '5vw',
//                     textAlign: 'center',
//                     border:'1px solid black'                   }}>
//                     <img src="value_4.png" alt="Image 4" style={{ 
//                         maxWidth: '100%',
//                         maxHeight: '100%',
//                         borderRadius: '10px 10px 0 0', // Round the top corners
//                     }} />
//                     <p style={{marginTop:'-8vw',fontSize:'1.8vw'}}>Have unlimited fun & learning with activities</p>
//                 </div>
//             </div>
//             <div style={{display:'flex', marginLeft: '20px',textAlign:'right' ,justifyContent:'right'}}>
//                 <div>
//                 <h2 style={{marginTop:'7vw',fontSize:'2vw',color:'red'}}>Rose</h2>
//                 <h3 style={{fontSize:'1.5vw',width:'40vw',color:'brown'}}>A sassy, and witty feline, Rose is always up to some mischief. She is curious and creative, tiny yet fierce.</h3>
//                 </div>
//                 <img src="rose.png" alt="Right Image" style={{ maxWidth: '30vw', height: '25vw' }} />

//             </div>
//         </div>
//         </div>

//         <div style={{ display: 'flex', justifyContent: 'center'}}>
//             <div style={{ marginRight: '1.5vw', textAlign: 'center',display:'flex' }}>
//                 <h2 style={{ writingMode: 'vertical-lr',marginRight:'0.8vw',marginLeft:'-1%', backgroundColor:'lightgreen',fontSize:'1.8vw'}}>Learning Outcomes</h2>
//                 <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    
//                         <div
//                             style={{
//                                 width: '30vw',
//                                 height: '5vw',
//                                 borderRadius: '20px',
//                                 margin: '10px',
//                                 backgroundColor: 'lightgreen',
//                                 display: 'flex',
//                                 justifyContent: 'center',
//                                 alignItems: 'center',
//                                 transition: 'transform 0.3s', // Adding transition for smooth effect
//                             }}
//                             onMouseEnter={handleHover}
//                             onMouseLeave={handleHoverOut}
//                         >
//                             <p style={{fontSize:'1.8vw'}}>Music & Dance</p>
//                         </div>
//                         <div
//                             style={{
//                               width: '30vw',
//                               height: '5vw',
//                                 borderRadius: '20px',
//                                 margin: '10px',
//                                 backgroundColor: 'lightgreen',
//                                 display: 'flex',
//                                 justifyContent: 'center',
//                                 alignItems: 'center',
//                                 transition: 'transform 0.3s', // Adding transition for smooth effect
//                             }}
//                             onMouseEnter={handleHover}
//                             onMouseLeave={handleHoverOut}
//                         >
//                             <p style={{fontSize:'1.8vw'}}>Self Expression</p>
//                         </div> <div
//                             style={{
//                               width: '30vw',
//                               height: '5vw',
//                                 borderRadius: '20px',
//                                 margin: '10px',
//                                 backgroundColor: 'lightgreen',
//                                 display: 'flex',
//                                 justifyContent: 'center',
//                                 alignItems: 'center',
//                                 transition: 'transform 0.3s', // Adding transition for smooth effect
//                             }}
//                             onMouseEnter={handleHover}
//                             onMouseLeave={handleHoverOut}
//                         >
//                             <p style={{fontSize:'1.8vw'}}>Exploration</p>
//                         </div> <div
//                             style={{
//                               width: '30vw',
//                               height: '5vw',
//                                 borderRadius: '20px',
//                                 margin: '10px',
//                                 backgroundColor: 'lightgreen',
//                                 display: 'flex',
//                                 justifyContent: 'center',
//                                 alignItems: 'center',
//                                 transition: 'transform 0.3s', // Adding transition for smooth effect
//                             }}
//                             onMouseEnter={handleHover}
//                             onMouseLeave={handleHoverOut}
//                         >
//                             <p style={{fontSize:'1.8vw'}}>Maths, Science, English </p>
//                         </div> <div
//                             style={{
//                               width: '30vw',
//                               height: '5vw',
//                                 borderRadius: '20px',
//                                 margin: '10px',
//                                 backgroundColor: 'lightgreen',
//                                 display: 'flex',
//                                 justifyContent: 'center',
//                                 alignItems: 'center',
//                                 transition: 'transform 0.3s', // Adding transition for smooth effect
//                             }}
//                             onMouseEnter={handleHover}
//                             onMouseLeave={handleHoverOut}
//                         >
//                             <p style={{fontSize:'1.8vw'}}>Social & Emotional Intelligence </p>
//                         </div> <div
//                             style={{
//                               width: '30vw',
//                               height: '5vw',
//                                 borderRadius: '20px',
//                                 margin: '10px',
//                                 backgroundColor: 'lightgreen',
//                                 display: 'flex',
//                                 justifyContent: 'center',
//                                 alignItems: 'center',
//                                 transition: 'transform 0.3s', // Adding transition for smooth effect
//                             }}
//                             onMouseEnter={handleHover}
//                             onMouseLeave={handleHoverOut}
//                         >
//                             <p style={{fontSize:'1.8vw'}}>Creativity & Curiosity </p>
//                         </div>
//                 </div>
//             </div>

//             <div style={{ marginLeft: '1.5vw', textAlign: 'center',display:'flex' }}>
//                 <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
//                         <div
//                             style={{
//                               width: '30vw',
//                               height: '5vw',
//                                 borderRadius: '20px',
//                                 margin: '10px',
//                                 backgroundColor: 'skyblue',
//                                 display: 'flex',
//                                 justifyContent: 'center',
//                                 alignItems: 'center',
//                                 transition: 'transform 0.3s', // Adding transition for smooth effect
//                             }}
//                             onMouseEnter={handleHover}
//                             onMouseLeave={handleHoverOut}
//                         >
//                             <p style={{fontSize:'1.8vw'}}>Parent-Child Bonding</p>
//                         </div>
//                         <div
//                             style={{
//                               width: '30vw',
//                               height: '5vw',
//                                 borderRadius: '20px',
//                                 margin: '10px',
//                                 backgroundColor: 'skyblue',
//                                 display: 'flex',
//                                 justifyContent: 'center',
//                                 alignItems: 'center',
//                                 transition: 'transform 0.3s', // Adding transition for smooth effect
//                             }}
//                             onMouseEnter={handleHover}
//                             onMouseLeave={handleHoverOut}
//                         >
//                             <p style={{fontSize:'1.8vw'}}>Most Meaningful Screentime</p>
//                         </div>
//                         <div
//                             style={{
//                               width: '30vw',
//                                 height: '5vw',
//                                 borderRadius: '20px',
//                                 margin: '10px',
//                                 backgroundColor: 'skyblue',
//                                 display: 'flex',
//                                 justifyContent: 'center',
//                                 alignItems: 'center',
//                                 transition: 'transform 0.3s', // Adding transition for smooth effect
//                             }}
//                             onMouseEnter={handleHover}
//                             onMouseLeave={handleHoverOut}
//                         >
//                             <p style={{fontSize:'1.8vw'}}>Progress Tracking</p>
//                         </div>
//                         <div
//                             style={{
//                               width: '30vw',
//                               height: '5vw',
//                                 borderRadius: '20px',
//                                 margin: '10px',
//                                 backgroundColor: 'skyblue',
//                                 display: 'flex',
//                                 justifyContent: 'center',
//                                 alignItems: 'center',
//                                 transition: 'transform 0.3s', // Adding transition for smooth effect
//                             }}
//                             onMouseEnter={handleHover}
//                             onMouseLeave={handleHoverOut}
//                         >
//                             <p style={{fontSize:'1.8vw'}}>High Quality Interactive Content</p>
//                         </div>
//                         <div
//                             style={{
//                               width: '30vw',
//                               height: '5vw',
//                                 borderRadius: '20px',
//                                 margin: '10px',
//                                 backgroundColor: 'skyblue',
//                                 display: 'flex',
//                                 justifyContent: 'center',
//                                 alignItems: 'center',
//                                 transition: 'transform 0.3s', // Adding transition for smooth effect
//                             }}
//                             onMouseEnter={handleHover}
//                             onMouseLeave={handleHoverOut}
//                         >
//                             <p style={{fontSize:'1.8vw'}}>Regular Content</p>
//                         </div>
//                         <div
//                             style={{
//                               width: '30vw',
//                               height: '5vw',
//                                 borderRadius: '20px',
//                                 margin: '10px',
//                                 backgroundColor: 'skyblue',
//                                 display: 'flex',
//                                 justifyContent: 'center',
//                                 alignItems: 'center',
//                                 transition: 'transform 0.3s', // Adding transition for smooth effect
//                             }}
//                             onMouseEnter={handleHover}
//                             onMouseLeave={handleHoverOut}
//                         >
//                             <p style={{fontSize:'1.8vw'}}>Safe & Ad-free</p>
//                         </div>
                    
//                 </div>
//                 <h2 style={{ writingMode: 'vertical-lr',marginLeft:'0.8vw',marginRight:'-1%',backgroundColor:'skyblue',fontSize:'1.8vw' }}>What We Offer</h2>

//             </div>
//         </div>
      
//       <div id='cnr' style={{ display: 'flex', justifyContent: 'center',gap:'2%' ,marginTop:'5vw'}}>
            
//              <div>
//               <h2 style={{textAlign:'center',fontSize:'2vw',color:'purple'}}>What our Expert says</h2>
//             <div 
//                 style={boxStyle} 
//                 onMouseEnter={handleHover1} 
//                 onMouseLeave={handleHoverOut}
//             >
//                 <img src="anuradha.png" alt="Image 1" style={imageStyle} />

//                 <p style={paraStyle}>"For children, everything is a wonder and when we allow them to satisfy their curiosity through safe, self initiated and playful exploration, learning occurs naturally. My Adventure Book is a beautiful attempt to help children learn through context based task and activities that are purposeful,meaningful,socially significant and enjoyable"</p>
//                 <h3  style={{textAlign:'center',fontSize:'1.5vw',color:'purple'}}>Dr. Anuradha<br/> Pediatrician Parenting coach and Adolescent specialist, 25+years exp.</h3>
//             </div></div>
//             <div>
//               <h2 style={{textAlign:'center',fontSize:'2vw',color:'magenta'}}>Child Safety</h2>
//             <div 
//                 style={boxStyle} 
//                 onMouseEnter={handleHover1} 
//                 onMouseLeave={handleHoverOut}
//             >
//                 <img src="safety.png" alt="Image 1" style={ {marginTop:'-0.8vw', maxWidth: '70%',  height: 'auto',}} />

//                 <p style={paraStyle}>My Adventure Book makes the online safety of you and your children a top priority. We only collect data in the interest of the user, to create the most personalized educational and fun experience possible. We will never sell your data to third parties and our platform is ad-free.</p>
//                 <h3  style={{textAlign:'center',fontSize:'1.5vw',color:'magenta'}}>We commit to a responsible and ethical use of data.</h3>
//             </div></div>
//             <div>
//               <h2 style={{textAlign:'center',fontSize:'2vw',color:'green'}}>Plant a Tree</h2>
//             <div 
//                 style={boxStyle} 
//                 onMouseEnter={handleHover1} 
//                 onMouseLeave={handleHoverOut}
//             >
//                 <img src="plant_tree.png" alt="Image 1" style={imageStyle} />

//                 <p style={paraStyle}>
//                 As part of our  Corporate Nature Responsibility (CNR), we will be planting a total of 1111 trees. Please follow us on social media for further updates. If you would like to support this initiative in your own capacity, do write to us at contact@myadventurebook.org                  </p>
//                   <h3  style={{textAlign:'center',fontSize:'1.5vw',color:'green'}}>Corporate Nature Responsibility</h3>
//             </div></div>
//         </div>
        


//       <div id='faq' style={{ textAlign: 'center' ,marginBottom:'5%'}}>
//       <h2 style={{ fontSize: '3vw', margin: '20px 0',fontWeight:'bold',marginBottom:'5%',marginTop:'5%',color:'orange' }}>Frequently Asked Questions</h2>
      
//       {/* FAQ divisions */}
//       <div style={{ maxWidth: '75vw', margin: '0 auto'}}>

//         <div style={{ display: 'flex' }}>
//           <div style={{ width: '40%', textAlign: 'left' }}>
//             <h3 style={{ fontSize: '1.5vw' }}>
//             What is My Adventure Book?              
//             </h3>
//           </div>
//           <div style={{width:'5%'}}></div>
//           <div style={{ width: '55%', textAlign: 'left' }}>
//             <p style={{fontSize: '1.5vw' }}>
//             My Adventure Book is a holistic learning platform where kids star in their own adventures. 

// They go on exciting fun-filled journeys while learning important & interesting lessons that they can apply to 

// their schools and day-to-day lives.                </p>
//           </div>
//         </div>
//         <hr style={{ width: '100%', borderWidth: '1.5px', borderColor: 'rgba(0, 0, 0, 0.1)' }} />
        
        
//         <div style={{ display: 'flex' }}>
//           <div style={{ width: '40%', textAlign: 'left' }}>
//             <h3 style={{ fontSize: '1.5vw' }}>
//             What are the benefits of My Adventure Book?             </h3>
//           </div>
//           <div style={{width:'5%'}}></div>
//           <div style={{ width: '55%', textAlign: 'left' }}>
//             <p style={{fontSize: '1.5vw' }}>
//             My Adventure book provides a perfect blend of learning and entertainment to young learners. They can revise 

// maths concepts, improve their reading skills, sharpen their artistic skills, build socio-emotional skills, write and share creative 

// content and much more.</p>
//           </div>
//         </div>
//         <hr style={{ width: '100%', borderWidth: '1.5px', borderColor: 'rgba(0, 0, 0, 0.1)' }} />
        

//         <div style={{ display: 'flex' }}>
//           <div style={{ width: '40%', textAlign: 'left' }}>
//             <h3 style={{ fontSize: '1.5vw' }}>
//             Is My Adventure Book for a certain age?            </h3>
//           </div>
//           <div style={{width:'5%'}}></div>
//           <div style={{ width: '55%', textAlign: 'left' }}>
//             <p style={{fontSize: '1.5vw' }}>
//             Anyone with a love for adventures would enjoy My Adventure Book but it’s especially great 

// for kids aged 3+ years.</p>
//           </div>
//         </div>
//         <hr style={{ width: '100%', borderWidth: '1.5px', borderColor: 'rgba(0, 0, 0, 0.1)' }} />
        

//         <div style={{ display: 'flex' }}>
//           <div style={{ width: '40%', textAlign: 'left' }}>
//             <h3 style={{ fontSize: '1.5vw' }}>
//             Where can I download My Adventure Book app?             </h3>
//           </div>
//           <div style={{width:'5%'}}></div>
//           <div style={{ width: '55%', textAlign: 'left' }}>
//             <p style={{fontSize: '1.5vw' }}>
//             Users can get early access to the beta release of My Adventure Book by registering their email id in this <a href='https://forms.gle/ypT3BFm4ujdWaNGa6'>Link</a>.
//             <br/>Once they are registered they will receive an email with the download link. After downloading the app, users can create an account. </p>
//           </div>
//         </div>
//         <hr style={{ width: '100%', borderWidth: '1.5px', borderColor: 'rgba(0, 0, 0, 0.1)' }} />
        

//         <div style={{ display: 'flex' }}>
//           <div style={{ width: '40%', textAlign: 'left' }}>
//             <h3 style={{ fontSize: '1.5vw' }}>
//             Can I use My Adventure Book on multiple devices?             </h3>
//           </div>
//           <div style={{width:'5%'}}></div>
//           <div style={{ width: '55%', textAlign: 'left' }}>
//             <p style={{fontSize: '1.5vw' }}>
//             Yes. After creating an account on My Adventure book you can use your registered email id and password to log into any of your devices.<br/>

// The current set of devices and Operating systems it supports are as follows:<br/>

// Android: Version 10.0 and above<br/>

// iOS: Version 11.0 and above </p>
//           </div>
//         </div>
//         <hr style={{ width: '100%', borderWidth: '1.5px', borderColor: 'rgba(0, 0, 0, 0.1)' }} />
        

//         <div style={{ display: 'flex' }}>
//           <div style={{ width: '40%', textAlign: 'left' }}>
//             <h3 style={{ fontSize: '1.5vw' }}>
//             How do I create an account in the My Adventure Book app?            </h3>
//           </div>
//           <div style={{width:'5%'}}></div>
//           <div style={{ width: '55%', textAlign: 'left' }}>
//             <p style={{fontSize: '1.5vw' }}>
//             To create an account, follow these steps:<br/>
// Step 1: Download  My Adventure Book app by registering your email id in the form on this <a href='https://forms.gle/ypT3BFm4ujdWaNGa6'> LINK</a>. <br/>

// Step 2: After installing the app, open the app and click on the ‘Create Free Account’ button.<br/>

// Step 3: Enter your name, email and password and click create account. <br/>

// Step 4: Verify your registered email id and your registration process is complete.</p>
//           </div>
//         </div>
//         <hr style={{ width: '100%', borderWidth: '1.5px', borderColor: 'rgba(0, 0, 0, 0.1)' }} />
        

//         <div style={{ display: 'flex' }}>
//           <div style={{ width: '40%', textAlign: 'left' }}>
//             <h3 style={{ fontSize: '1.5vw' }}>
//             How do I verify my email?            </h3>
//           </div>
//           <div style={{width:'5%'}}></div>
//           <div style={{ width: '55%', textAlign: 'left' }}>
//             <p style={{fontSize: '1.5vw' }}>
//             When signing up for a new account on the My Adventure Book app, you'll need to verify your email address. To verify your email, check your email account for a message that says "Your Registration successful" in the subject line, and then click on the button that says "Verify". You will receive a success message as “ Verified”. You can then start using My Adventure Book app. 

// </p>
//           </div>
//         </div>
//         <hr style={{ width: '100%', borderWidth: '1.5px', borderColor: 'rgba(0, 0, 0, 0.1)' }} />
        

//         <div style={{ display: 'flex' }}>
//           <div style={{ width: '40%', textAlign: 'left' }}>
//             <h3 style={{ fontSize: '1.5vw' }}>
//             How do I create my child's profile?
// </h3>
//           </div>
//           <div style={{width:'5%'}}></div>
//           <div style={{ width: '55%', textAlign: 'left' }}>
//             <p style={{fontSize: '1.5vw' }}>
//             To add your child’s profile, go to Profile selection page and press “Add child” button  and  input your child’s name, age, relationship to the child and select profile picture. Click ‘Okay’ and your child’s profile will be added.  </p>
//           </div>
//         </div>
//         <hr style={{ width: '100%', borderWidth: '1.5px', borderColor: 'rgba(0, 0, 0, 0.1)' }} />
        

//         <div style={{ display: 'flex' }}>
//           <div style={{ width: '40%', textAlign: 'left' }}>
//             <h3 style={{ fontSize: '1.5vw' }}>
//             How many child accounts can we have per user account?            </h3>
//           </div>
//           <div style={{width:'5%'}}></div>
//           <div style={{ width: '55%', textAlign: 'left' }}>
//             <p style={{fontSize: '1.5vw' }}>
//             My Adventure Book allows each user profile to create upto a maximum of 2 child profiles in the app. </p>
//           </div>
//         </div>
//         <hr style={{ width: '100%', borderWidth: '1.5px', borderColor: 'rgba(0, 0, 0, 0.1)' }} />
        

//         <div style={{ display: 'flex' }}>
//           <div style={{ width: '40%', textAlign: 'left' }}>
//             <h3 style={{ fontSize: '1.5vw' }}>
//             Do I need internet access to use My Adventure Book?            </h3>
//           </div>
//           <div style={{width:'5%'}}></div>
//           <div style={{ width: '55%', textAlign: 'left' }}>
//             <p style={{fontSize: '1.5vw' }}>
//             Yes, My Adventure Book can only be used while connected to a WiFi network or a cellular network. It allows you to download each Adventure as a mp4 media and you can play as many times as you like. 

// </p>
//           </div>
//         </div>
//         <hr style={{ width: '100%', borderWidth: '1.5px', borderColor: 'rgba(0, 0, 0, 0.1)' }} />
        

//         <div style={{ display: 'flex' }}>
//           <div style={{ width: '40%', textAlign: 'left' }}>
//             <h3 style={{ fontSize: '1.5vw' }}>
//             Why is my story locked?            </h3>
//           </div>
//           <div style={{width:'5%'}}></div>
//           <div style={{ width: '55%', textAlign: 'left' }}>
//             <p style={{fontSize: '1.5vw' }}>
//             If your story is locked or not playing you need to create your user avatar first. In order to create your avatar, click a selfie or upload a picture as explained in the “Steps” section. After this, select the avatar suit of your choice and click done. Once your user avatar is created the story will be automatically unlocked. 
// </p>
//           </div>
//         </div>
//         <hr style={{ width: '100%', borderWidth: '1.5px', borderColor: 'rgba(0, 0, 0, 0.1)' }} />
        

//         <div style={{ display: 'flex' }}>
//           <div style={{ width: '40%', textAlign: 'left' }}>
//             <h3 style={{ fontSize: '1.5vw' }}>
//             Does My Adventure Book contain ads?            </h3>
//           </div>
//           <div style={{width:'5%'}}></div>
//           <div style={{ width: '55%', textAlign: 'left' }}>
//             <p style={{fontSize: '1.5vw' }}>
//             No! My Adventure Book is 100% ad free. </p>
//           </div>
//         </div>
//         <hr style={{ width: '100%', borderWidth: '1.5px', borderColor: 'rgba(0, 0, 0, 0.1)' }} />
        

   
//       </div>
//     </div>
              
//       <Footer />
//       <div style={{ position: 'fixed', bottom: '20px', right: '20px', display: 'flex', flexDirection: 'column', alignItems: 'center', zIndex: '999' }}>
//         <div id='contact'
//           style={{
//             width: '50px',
//             height: '50px',
//             backgroundColor: '#5E17EB',
//             borderRadius: '50%',
//             marginBottom: '10px',
//             display: 'flex',
//             alignItems: 'center',
//             justifyContent: 'center',
//             cursor: 'pointer',
//           }}
//           onClick={scrollToTop}
//         >
//           <span style={{ color: 'white', fontSize: '24px', fontWeight: 'bold' }}>↑</span>
//         </div>
//         <div
//           style={{
//             width: '50px',
//             height: '50px',
//             backgroundColor: '#5E17EB',
//             borderRadius: '50%',
//             display: 'flex',
//             alignItems: 'center',
//             justifyContent: 'center',
//             cursor: 'pointer',
//           }}
//           onClick={scrollToBottom}
//         >
//           <span style={{ color: 'white', fontSize: '24px', fontWeight: 'bold' }}>↓</span>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Home;
