import React, { useState } from 'react';
import { FaFacebook, FaInstagram, FaSquareXTwitter, FaLinkedin, FaYoutube } from 'react-icons/fa6';

const Footer = () => {
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [showTermsOfService, setShowTermsOfService] = useState(false);

  const openPrivacyPolicy = () => {
    setShowPrivacyPolicy(true);
  };

  const closePrivacyPolicy = () => {
    setShowPrivacyPolicy(false);
  };

  const openTermsOfService = () => {
    setShowTermsOfService(true);
  };

  const closeTermsOfService = () => {
    setShowTermsOfService(false);
  };

  return (
    <footer style={{ position: 'relative', textAlign: 'center' }}>
      <div className="follow-us">
        <h2 style={{fontWeight:'lighter',fontSize:'3vw'}}>Follow us</h2>
        <a style={{fontSize:'5vw',color:'inherit',textDecoration:'none'}} target="_blank" href="https://www.facebook.com/people/My-Adventure-Book/100076280179861/"><FaFacebook style={{ color: '#3b5998' }}/></a>
        &nbsp;&nbsp;
        <a style={{fontSize:'5vw',color:'inherit',textDecoration:'none'}} target="_blank" href="https://www.instagram.com/mab_appforkids/"><FaInstagram style={{ color: '#E4405F' }}/></a>
        &nbsp;&nbsp;
        <a style={{fontSize:'5vw',color:'inherit',textDecoration:'none'}} target="_blank" href="https://twitter.com/MAB_app"><FaSquareXTwitter style={{  }}/></a>
        &nbsp;&nbsp;
        <a style={{fontSize:'5vw',color:'inherit',textDecoration:'none'}} target="_blank" href="https://www.linkedin.com/company/orion-eduverse/"><FaLinkedin style={{ color: '#0077B5' }}/></a>
        &nbsp;&nbsp;
        <a style={{fontSize:'5vw',color:'inherit',textDecoration:'none'}} target="_blank" href="https://www.youtube.com/channel/UCbLe7F1uCrJBZsuR_TjKZ6g"><FaYoutube style={{ color: '#FF0000' }}/></a>
      </div>
      <div className="contact">
    <hr style={{width:'75%'}}></hr>
        <p>Email: <a href="mailto:contact@myadventurebook.org">contact@myadventurebook.org,  mabkart777@gmail.com</a> <br/> Phone: <a href="tel:+919380706694">+91 9380 706 694,  +919677028480</a></p>
        {/* <p>Orion Eduverse Pvt Ltd. Kanke Block, Arsandey, Ranchi, Jharkhand 834006</p> */}
      </div>
      <div className="terms">
      <p style={{fontWeight:'lighter'}}>Read our <span style={{ cursor: 'pointer', textDecoration: 'underline' }} onClick={openTermsOfService}>Terms & Conditions</span>. Copyright MAB Kart 2022 Allrights reserved</p>
      </div>
      {showPrivacyPolicy && (
        <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={closePrivacyPolicy}>

          <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '5px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)', overflowY: 'auto', maxHeight: '80vh', maxWidth: '80vw' ,textAlign:'left'}}>
          <button onClick={closePrivacyPolicy}>Close</button>

            <h2>Terms Of Use</h2>
            <p>
            <p>By using or by purchasing any products or experience packages at My Adventure Book website you are bound by the following terms and conditions that are applicable as a contract between you and MAB KART</p>

<p><b>Refunds and Cancellations</b></p>

<p>Mab Kart  works with a variety of partners in India to provide the vast range of items and experiences to you. Our policies on refunds, exchange and cancellations are dependent on and restricted by the policies of these partners.</p>

<p>1. PRODUCTS: All products shipped carry their original manufacturer's warranty as applicable. If you feel the product is defective or incomplete please contact us immediately and we will work out a solution to your satisfaction. Products can be returned within 10 days in case of defects or functional problems. You will need to send these back to us to get credit for the amount paid. Returns are not processed for any products that are tampered with, in used condition, or with damaged packaging etc. Personalised items are not covered under refunds unless there is transit damage or other defects. Read details for Clone Dolls (bobbleheads) in the separate refunds section below.</p>

<p>If the address is incorrect or the recipient is not available we will not be able to deliver again. No refunds are possible in such cases</p>

<p>Refunds are always made to the person who made the purchase, and if originally purchased by credit or debit card, the refund will be credited by our payment partner Razorpay as per their available mode of transfers.</p>
<p>Shipping charges for returns incurred by customers will not be refunded. This will be cost to the customer.</p>
<p><b>Delivery</b></p>

<p>All gifts and experiences can be sent to you, directly to the recipient, or to another address at your request. Please make sure the address that you provide before making the final purchase is the intended address. You can always update the address before making the purchase. </p>

<p>Please ensure the correct delivery address with phone number in your order. We are not responsible for any delays or non-delivery that results from incorrect addresses, or in case premises are locked</p>

<p>If a parcel goes astray in delivery, we will provide a duplicate free of charge. Please contact us as soon as possible for this.</p>

<p>There are no guarantees for courier shipments - we try and deliver to you as required however in various conditions beyond our control there could be delays</p>

<p><b>Experience Description:</b></p>

<p>The information provided by Mab Kart on website and social media channels are correct a</p>

<p>the time of print. However, the content or format of experiences may change slightly during the year. Current details of each gift are available on our website.

</p>

<p><b>Exchange Rates</b></p>

<p>Our base pricing is in Indian Rupees however you may use a credit card from any other country to pay for products. The prices displayed at the time of payment are as per Razorpay exchange rates policies and governed by them. Mab Kart does not decide the exchange rate variation of any sort whatsoever.

</p>



<p><b>Complaints & Liability:</b></p>

<p>We welcome your feedback on all of our gifts and experiences. If you have a problem or complaint on the day, please bring it to our attention via email to mabkart777@gmail.com as soon as possible, so we have a chance to put matters right. If your problem is not resolved on the day, please allow us some time and we will be happy to take the matter up with the supplier</p>

<p>Mab Kart, its associated partners, suppliers and their employees or Directors are not responsible for claims arising from personal injury, death due to the negligence, delays in implementation or any other losses of any kind that you may face while using the products purchased. </p>



<p><b>International Shipping:</b></p>

<p>We ship some products outside India. As per international shipping regulations there may be Customs Duty or other taxes that may be levied by the Governments of the consignee countries. In such cases these duties need to be paid by the consignee. We are not aware of regulations for different products in different countries and cannot predict this at the time of purchase. Delivery times are only estimates and we do not provide guarantees for these because of a variety of factors involved in international shipping. Due to the nature of these shipments we are unable to offer any refunds for the same.</p>

<p><b>Copyright & Others:</b></p>

<p>All prices quoted in our promotional literature and on our website do not include taxes and these may be charged extra as you check out. We reserve the right to increase the price of any item without notice</p>

<p>The text, illustrations and images used on My Adventure Book website, promotional literature, EDMs are protected by copyright and must not be copied in whole or in part without the express permission of Mab Kart.

</p>

<p>Mab Kart reserves the right to change these terms from time to time without reference to customers. These terms and conditions do not affect your statutory rights as a consumer, and are governed by and in accordance with Indian law. The purchaser and Mab Kart agree that any problems fall under the jurisdiction of the Ranchi courts.</p>





























            </p>
            <button onClick={closePrivacyPolicy}>Close</button>
          </div>
        </div>
      )}
      {showTermsOfService && (
        <div style={{ position: 'fixed', top: 0, left: 0, width: '100%', height: '100%', backgroundColor: 'rgba(0, 0, 0, 0.5)', display: 'flex', justifyContent: 'center', alignItems: 'center' }} onClick={closeTermsOfService}>
          <div style={{ backgroundColor: 'white', padding: '20px', borderRadius: '5px', boxShadow: '0 0 10px rgba(0, 0, 0, 0.3)', overflowY: 'auto', maxHeight: '80vh', maxWidth: '80vw', textAlign:'left' }}>
          <button onClick={closeTermsOfService}>Close</button>
<h2>Terms Of Use for MAB KART</h2>
            <p>
<p>Effective 15th July 2024</p>

<p>By using or by purchasing any products or experience packages at My Adventure Book website for Mab Kart products you are bound by the following terms and conditions that are applicable as a contract between you and MAB KART.</p>
<p><b>Refunds and Cancellations and Shipping Policy</b></p>
<p>Mab Kart (business Name: SAURAV KUMAR) works with a variety of partners in India to provide the vast range of items and experiences to you. Our policies on refunds, exchange and cancellations are dependent on and restricted by the policies of these partners.

 
</p>
<p><b>PRODUCTS</b></p>

<p><b>Shipping process :</b> Shipping process starts on the same day if the order is placed before 2pm. If the order is placed after 2pm the shipping process starts the next day.</p>

<p><b>Delivery:</b> Within India, we expect the shipment to arrive in 7 to 10 days depending upon the courier service. For outside India, the delivery may take approx. 15 to 20 days depending upon the location. Please note that we use the one of the most reliable courier services for the. shipment of your orders. 
<p>Shipping charges for returns incurred by customers will not be refunded. This will be cost to the customer</p>
<p>All products shipped carry their original manufacturer's warranty as applicable. If you feel the product is defective or incomplete please contact us immediately and we will work out a solution to your satisfaction</p>

<p><b>Return policy: </b></p>

<p>Products can be returned within 10 days in case of defects or functional problems. You will need to send these back to us to get credit for the amount paid. Returns are not processed for any products that are tampered with, in used condition, or with damaged packaging etc. Personalised items are not covered under refunds unless there is transit damage or other defects. If the address is incorrect or the recipient is not available we will not be able to deliver again. No refunds are possible in such cases. Once the case for the returned item after internal inspection is found to be genuine and satisfactory, refunds will be initiated within 15 days from the date of receiving the returned item. </p>
<p>Refunds are always made to the person who made the purchase, and if originally purchased by credit or debit card, the refund will be credited by our payment partner Razorpay as per their available mode of transfers.</p>
<p>Shipping charges for returns incurred by customers will not be refunded. This will be cost to the customer.</p>
<p><b>International Shipping:</b></p>

<p>We ship some products outside India. As per international shipping regulations there may be Customs Duty or other taxes that may be levied by the Governments of the consignee countries. In such cases these duties need to be paid by the consignee. We are not aware of regulations for different products in different countries and cannot predict this at the time of purchase. Delivery times are only estimates and we do not provide guarantees for these because of a variety of factors involved in international shipping. Due to the nature of these shipments we are unable to offer any refunds for the same.

</p>

<p><b>Delivery</b></p>

<p>All gifts and experiences can be sent to you, directly to the recipient, or to another address at your request. Please make sure the address that you provide before making the final purchase is the intended address. You can always update the address before making the purchase. </p>

<p>Please ensure the correct delivery address with phone number in your order. We are not responsible for any delays or non-delivery that results from incorrect addresses, or in case premises are locked. </p>

<p>If a parcel goes astray in delivery, we will provide a duplicate free of charge. Please contact us as soon as possible for this.</p>

<p>Items will be delivered on or before the actual delivery date required.

</p>

<p>There are no guarantees for courier shipments - we try and deliver to you as required however in various conditions beyond our control there could be delays.</p>

<p><b>Experience Description:</b></p>

<p>The information provided by Mab Kart on website and social media channels are correct at the time of print. However, the content or format of experiences may change slightly during the year. Current details of each gift are available on our website.</p>

<p><b>Exchange Rates:</b></p>

<p>Our base pricing is in Indian Rupees however you may use a credit card from any other country to pay for products. The prices displayed at the time of payment are as per Razorpay exchange rates policies and governed by them. Mab Kart does not decide the exchange rate variation of any sort whatsoever.</p>


<p><b>Complaints & Liability:</b></p>
<p>We welcome your feedback on all of our gifts and experiences. If you have a problem or complaint on the day, please bring it to our attention via email to mabkart777@gmail.com as soon as possible, so we have a chance to put matters right. If your problem is not resolved on the day, please allow us some time and we will be happy to take the matter up with the supplier.</p>
<p>Mab Kart, its associated partners, suppliers and their employees or Directors are not responsible for claims arising from personal injury, death due to the negligence, delays in implementation or any other losses of any kind that you may face while using the products purchased. </p>
</p>
<p><b>Copyright & Others:</b></p>
<p>All prices quoted in our promotional literature and on our website do not include taxes and these may be charged extra as you check out. We reserve the right to increase the price of any item without notice.

</p>
<p>The text, illustrations and images used on My Adventure Book website, promotional literature, EDMs are protected by copyright and must not be copied in whole or in part without the express permission of Mab Kart.</p>
<p>Mab Kart reserves the right to change these terms from time to time without reference to customers. These terms and conditions do not affect your statutory rights as a consumer, and are governed by and in accordance with Indian law. The purchaser and Mab Kart agree that any problems fall under the jurisdiction of the Ranchi courts.</p>
<h2>Welcome to Orion Eduverse Privacy Policy</h2>
            <h2>Terms & Conditions</h2>
            <p>
<p>Effective date: October 4th, 2022</p>

<p>Welcome to Orion Eduverse Private Ltd. Please read on to learn the rules and restrictions that govern your use of our website(s), products, and services. If you have any questions, comments, or concerns regarding these terms or the Services, please contact us at:</p>

<p>Email: contact@myadventurebook.org Phone: +919380706694 Address: ORION EDUVERSE PRIVATE LIMITED C/O SUDARSHAN RAI, GOKUL MARG KANKE ROAD, AMIT NURSERY GALI, KANKE ROAD, ARSANDE, RANCHI, Jharkhand, India, 834006</p>

<p></p>

<p>These Terms of Use (the &ldquo;Terms&rdquo;) are a binding contract between you and Orion Eduverse Pvt. Ltd (&ldquo;Orion Eduverse,&rdquo; &ldquo;we&rdquo; and &ldquo;us&rdquo;). Your use of the website(s), products, and services in any way means that you agree to all of these Terms, and these Terms will remain in effect while you use website(s), products, and services. These Terms include the provisions in this document as well as those in the Privacy Policy, and the Copyright Dispute Policy. Your use of or participation in certain Services may also be subject to additional policies, rules and/or conditions (&ldquo;Additional Terms&rdquo;), which are incorporated herein by reference, and you understand and agree that by using or participating in any such Services, you agree to also comply with these Additional Terms.</p>

<p>Please read these Terms carefully. They cover important information about Services provided to you and any charges, taxes, and fees we bill you. These Terms include information about future changes to these Terms, automatic renewals, limitations of liability. PLEASE NOTE THAT YOUR USE OF AND ACCESS TO OUR SERVICES ARE SUBJECT TO THE FOLLOWING TERMS; IF YOU DO NOT AGREE TO ALL OF THE FOLLOWING, YOU MAY NOT USE OR ACCESS THE SERVICES IN ANY MANNER.</p>

<p></p>

<p>Will these Terms ever change?</p>

<p>We are constantly trying to improve our Services, so these Terms may need to change along with our Services. We reserve the right to change the Terms at any time, but if we do, we will place a notice on our site located at www.orioneduverse.com, and/or send you an email, and/or notify you by some other means.</p>

<p>If you don&rsquo;t agree with the new Terms, you are free to reject them; unfortunately, that means you will no longer be able to use the Services. If you use the Services in any way after a change to the Terms is effective, that means you agree to all of the changes.</p>

<p>Except for changes by us as described here, no other amendment or modification of these Terms will be effective unless in writing and signed by both you and us.</p>

<p></p>

<p>What about my privacy?</p>

<p>Orion Eduverse Pvt. Ltd takes the privacy of its users very seriously. For the current Orion Eduverse Privacy Policy, please click here.</p>

<p>Children&rsquo;s Online Privacy Protection Act</p>

<p>The Privacy Policy covers how we treat Personal Data that we gather when you access or use our Product &amp; Services. &ldquo;Personal Data&rdquo; means any information that identifies or relates to a particular individual, including a parent, guardian or child/kids, and also includes information referred to as &ldquo;personally identifiable information&rdquo; or &ldquo;personal information&rdquo; under applicable data privacy laws, rules or regulations. This Privacy Policy does not cover the practices of companies we don&rsquo;t own or control or people we don&rsquo;t manage.</p>

<p>Accessing our product and services requires parents to create user account using a valid email id and password. We allow up to 2 child accounts per user account. By creating a user account, it implies that parents agree to our privacy policy and terms and conditions and also consent to child&rsquo;s usage of our services within the framework and rules and regulations as laid down in our privacy policy and terms and conditions.</p>

<p>If we discover that we hold personal data relating to a user under 13 years of age without parental consent, we will take appropriate measures to ensure that we process that data according to the requirements of applicable laws and regulations or promptly delete the data from our records. If you have reason to believe we hold personal data relating to a user under 13 years of age without parental consent of that user, please contact us. We do not condition participation in our product &amp; Services on disclosure of more Personal Information from a child under thirteen (13) than is reasonably necessary for that participation, and we do not retain Personal Information from children under thirteen (13) longer than is reasonably necessary in order to fulfill the purpose for which it was disclosed.</p>

<p></p>

<p>What are the basics of using My Adventure Book?</p>

<p>You will be required to sign up for an account, using your existing email id and password and provide us with certain information or data, such as your contact information. You promise to provide us with accurate, complete, and updated registration information about yourself. You may not transfer your account to anyone else without our prior written permission. You represent and warrant that you are an individual of legal age to form a binding contract (or if not, you&rsquo;ve received your parent&rsquo;s or guardian&rsquo;s permission to use the Services and have gotten your parent or guardian to agree to these Terms on your behalf). You will only use the Services for your own internal, personal, non-commercial use, and not on behalf of or for the benefit of any third party, and only in a manner that complies with all laws that apply to you. If your use of the Services is prohibited by applicable laws, then you aren&rsquo;t authorized to use the Services. We can&rsquo;t and won&rsquo;t be responsible for your using the Services in a way that breaks the law.</p>

<p>You will not share your My Adventure Book login credentials, account or password with anyone, and you must protect the security of your My Adventure Book login credentials, account, password and any other access tools or credentials. You&rsquo;re responsible for any activity associated with your My Adventure Book login credentials and account. You acknowledge and agree that the availability of My Adventure Book is dependent on the third party stores from which you download the application, e.g., the App Store from Apple or the Android app market from Google (each an &ldquo;App Store&rdquo;). Each App Store may have its own terms and conditions to which you must agree before downloading My Adventure Book from such store, including the specific terms relating to Apple App Store set forth below. You agree to comply with, and your license to use My Adventure Book is conditioned upon your compliance with, such App Store terms and conditions. To the extent such other terms and conditions from such App Store are less restrictive than, or otherwise conflict with, the terms and conditions of these Terms of Use, the more restrictive or conflicting terms and conditions in these Terms of Use apply.</p>

<p></p>

<p>What about messaging?</p>

<p>As part of the Product &amp; Services, you may receive communications through the Services, including messages that Orion Eduverse Pvt Ltd sends you (for example, via email or SMS). When signing up for the Product &amp; Services, you may receive a welcome message and instructions on how to stop receiving messages. By signing up for the Product &amp; Services and providing us with your email address and/or wireless number, you confirm that you want Orion Eduverse Pvt Ltd to send you information that we think may be of interest to you, which may include Orion Eduverse Pvt Ltd using automated dialling technology to text you at the wireless number you provided, and you agree to receive communications from Orion Eduverse Pvt Ltd, and you represent and warrant that each person you register for the Services or for whom you provide a wireless phone number has consented to receive communications from Orion Eduverse Pvt Ltd. You agree to indemnify and hold Orion Eduverse Pvt Ltd harmless from and against any and all claims, liabilities, damages (actual and consequential), losses and expenses (including attorneys&rsquo; fees) arising from or in any way related to your breach of the foregoing.</p>

<p></p>

<p>Are there restrictions in how I can use the Services?</p>

<p>You represent, warrant, and agree that you will not contribute any Content or User Submission (each of those terms is defined below) or otherwise use the Product &amp; Services or interact with the Product &amp; Services in a manner that:</p>

<p> infringes or violates the intellectual property rights or any other rights of anyone else (including Orion Eduverse Pvt Ltd);</p>

<p> violates any law or regulation, including, without limitation, any applicable export control laws, privacy laws or any other purpose not reasonably intended by Orion Eduverse Pvt Ltd;</p>

<p> is dangerous, harmful, fraudulent, deceptive, threatening, harassing, defamatory, obscene, or otherwise objectionable;</p>

<p> Jeopardises the security of your My Adventure Book login credentials, account or anyone else&rsquo;s (such as allowing someone else to log in to the Product &amp; Services as you);</p>

<p> attempts, in any manner, to obtain the password, account, or other security information from any other user;</p>

<p> violates the security of any computer network, or cracks any passwords or security encryption codes;</p>

<p> runs Maillist, Listserv, any form of auto-responder or &ldquo;spam&rdquo; on the Services, or any processes that run or are activated while you are not logged into the Services, or that otherwise interfere with the proper working of the Services (including by placing an unreasonable load on the Services&rsquo; infrastructure);</p>

<p> &ldquo;crawls,&rdquo; &ldquo;scrapes,&rdquo; or &ldquo;spiders&rdquo; any page, data, or portion of or relating to the Product &amp; Services or Content (through use of manual or automated means);</p>

<p> copies or stores any significant portion of the Content; or</p>

<p> decompiles, reverse engineers, or otherwise attempts to obtain the source code or underlying ideas or information of or relating to the Services.</p>

<p>A violation of any of the foregoing is grounds for termination of your right to use or access the Services.</p>

<p></p>

<p>What are my rights in the Services?</p>

<p>The materials displayed or performed or available on or through the Services, including, but not limited to, text, graphics, data, articles, photos, images, illustrations, User Submissions (as defined below) and so forth (all of the foregoing, the &ldquo;Content&rdquo;) are protected by copyright and/or other intellectual property laws. You promise to abide by all copyright notices, trademark rules, information, and restrictions contained in any Content you access through the Services, and you won&rsquo;t use, copy, reproduce, modify, translate, publish, broadcast, transmit, distribute, perform, upload, display, license, sell, commercialize or otherwise exploit for any purpose any Content not owned by you, (i) without the prior consent of the owner of that Content or (ii) in a way that violates someone else&rsquo;s (including Orion Eduverse Pvt Ltd's) rights.</p>

<p>Subject to these Terms, we grant each user of the Services a worldwide, non-exclusive, non-sublicensable and non-transferable license to use (i.e., to download and display locally) Content solely for purposes of using the Services. Use, reproduction, modification, distribution or storage of any Content for any purpose other than using the Services is expressly prohibited without prior written permission from us. You understand that Orion Eduverse Pvt Ltd owns the Services. You won&rsquo;t modify, publish, transmit, participate in the transfer or sale of, reproduce (except as expressly provided in this Section), create derivative works based on, or otherwise exploit any of the Services. The Services may allow you to copy or download certain Content, but please remember that even where these functionalities exist, all the restrictions in this section still apply.</p>

<p>&#8203;</p>

<p>What about anything I contribute to the Services &ndash; do I have to grant any licenses to Orion Eduverse Pvt Ltd or to other users?</p>

<p>&#8203;</p>

<p>User Submissions</p>

<p>Anything you post, upload, share, store, or otherwise provide through the Services is your &ldquo;User Submission&rdquo;. Some User Submissions may be viewable by other users. You are solely responsible for all User Submissions you contribute to the Services. You represent that all User Submissions submitted by you are accurate, complete, up-to-date, and in compliance with all applicable laws, rules and regulations.</p>

<p>You agree that you will not post, upload, share, store, or otherwise provide through the Product &amp; Services any User Submissions that: (i) infringe any third party's copyrights or other rights (e.g., trademark, privacy rights, etc.); (ii) contain sexually explicit content or pornography; (iii) contain hateful, defamatory, or discriminatory content or incite hatred against any individual or group; (iv) exploit minors; (v) depict unlawful acts or extreme violence; (vi) depict animal cruelty or extreme violence towards animals; (vii) promote fraudulent schemes, multi-level marketing (MLM) schemes, get rich quick schemes, online gaming and gambling, cash gifting, work from home businesses, or any other dubious money-making ventures; or (viii) that violate any law.</p>

<p>&#8203;</p>

<p>Licenses</p>

<p>In order to display your User Submissions on the Product &amp; Services, and to allow other users to enjoy them (where applicable), you grant us certain rights in those User Submissions (see below for more information). Please note that all of the following licenses are subject to our Privacy Policy to the extent they relate to User Submissions that are also your personally-identifiable information.</p>

<p>For all User Submissions, you hereby grant Orion Eduverse Pvt Ltd a license to translate, modify (for technical purposes, for example, making sure your content is viewable on a mobile device as well as a computer) and reproduce and otherwise act with respect to such User Submissions, in each case to enable us to operate the Services, as described in more detail below. This is a license only &ndash; your ownership in User Submissions is not affected.</p>

<p>If you store a User Submission in your own personal My Adventure Book account, in a manner that is not viewable by any other user except you (a &ldquo;Personal User Submission&rdquo;), you grant Orion Eduverse Pvt Ltd the license above, as well as a license to display, perform, and distribute your Personal User Submission for the sole purpose of making that Personal User Submission accessible to you and providing the Services necessary to do so.</p>

<p>If you share a User Submission in a manner that only certain specified users can view (for example, a private message to one or more other users) (a &ldquo;Limited Audience User Submission&rdquo;), then you grant Orion Eduverse Pvt Ltd the licenses above, as well as a license to display, perform, and distribute your Limited Audience User Submission for the sole purpose of making that Limited Audience User Submission accessible to such other specified users, and providing the Services necessary to do so. Also, you grant such other specified users a license to access that Limited Audience User Submission, and to use and exercise all rights in it, as permitted by the functionality of the Services.</p>

<p>If you share a User Submission publicly on the Services and/or in a manner that more than just you or certain specified users can view, or if you provide us (in a direct email or otherwise) with any feedback, suggestions, improvements, enhancements, and/or feature requests relating to the Product &amp; Services (each of the foregoing, a &ldquo;Public User Submission&rdquo;), then you grant Orion Eduverse Pvt Ltd the licenses above, as well as a license to display, perform, and distribute your Public User Submission for the purpose of making that Public User Submission accessible to all Orion Eduverse Pvt Ltd users and providing the Services necessary to do so, as well as all other rights necessary to use and exercise all rights in that Public User Submission in connection with the Services and/or otherwise in connection with Orion Eduverse Pvt Ltd's business (including, for example, in connection with Orion Eduverse Pvt Ltd&rsquo;s marketing or promotional services). Also, you grant all other users of the Services a license to access that Public User Submission, and to use and exercise all rights in it, as permitted by the functionality of the Services.</p>

<p>You agree that the licenses you grant are royalty-free, perpetual, sublicensable, irrevocable, and worldwide, provided that when you delete your My Adventure Book account, we will stop displaying your User Submissions (other than Public User Submissions, which may remain fully available) to other users (if applicable), but you understand and agree that it may not be possible to completely delete that content from Orion Eduverse Pvt Ltd's records, and that your User Submissions may remain viewable elsewhere to the extent that they were copied or stored by other users.</p>

<p>Certain features of the Product &amp; Services may allow you to share information with others, including through your social networks or other services where you have an account (&ldquo;Third Party Accounts&rdquo;). When Content is authorized for sharing, we will clearly identify the Content you are authorized to redistribute and the ways you may redistribute it, usually by providing a &ldquo;share&rdquo; button on or near the Content. If you share information from the Services with others through your Third Party Accounts, such as your social networks, you authorize Orion Eduverse Pvt Ltd to share that information with the applicable Third Party Account provider. Please review the policies of any Third Party Account providers you share information with or through for additional information about how they may use your information. If you redistribute Content, you must be able to edit or delete any Content you redistribute, and you must edit or delete it promptly upon our request.</p>

<p>Finally, you understand and agree that Orion Eduverse Pvt Ltd, in performing the required technical steps to provide the Services to our users (including you), may need to make changes to your User Submissions to conform and adapt those User Submissions to the technical requirements of connection networks, devices, services, or media, and the foregoing licenses include the rights to do so.</p>

<p>&#8203;</p>

<p>What if I see something on the Services that infringes my copyright?</p>

<p>We respect others&rsquo; intellectual property rights, and we reserve the right to delete or disable Content alleged to be infringing, and to terminate the accounts of repeat alleged infringers. We are in process of formulating and implementing the copyright dispute policy and will update our Terms &amp; conditions notice in due course of time. Through channels described earlier we will inform our users in due course of time about copyright dispute policy. Until then we hold the right to resolve any future copyright infringement on a case to case basis.</p>

<p>&#8203;</p>

<p>Who is responsible for what I see and do on the Services?</p>

<p>Any information or Content publicly posted or privately transmitted through the Services is the sole responsibility of the person from whom such Content originated, and you access all such information and Content at your own risk, and we aren&rsquo;t liable for any errors or omissions in that information or Content or for any damages or loss you might suffer in connection with it. We cannot control and have no duty to take any action regarding how you may interpret and use the Content or what actions you may take as a result of having been exposed to the Content, and you hereby release us from all liability for you having acquired or not acquired Content through the Services. We can&rsquo;t guarantee the identity of any users with whom you interact in using the Services and are not responsible for which users gain access to the Services.</p>

<p>You are responsible for all Content you contribute, in any manner, to the Services, and you represent and warrant you have all rights necessary to do so, in the manner in which you contribute it.</p>

<p>The Services may contain links or connections to third-party websites or services that are not owned or controlled by Orion Eduverse Pvt Ltd. When you access third-party websites or use third-party services, you accept that there are risks in doing so, and that Orion Eduverse Pvt Ltd is not responsible for such risks.</p>

<p>Orion Eduverse Pvt Ltd has no control over, and assumes no responsibility for, the content, accuracy, privacy policies, or practices of or opinions expressed in any third-party websites or by any third party that you interact with through the Services. In addition, Orion Eduverse Pvt Ltd will not and cannot monitor, verify, censor or edit the content of any third-party site or service. We encourage you to be aware when you leave the Services and to read the terms and conditions and privacy policy of each third-party website or service that you visit or utilize. By using the Services, you release and hold us harmless from any and all liability arising from your use of any third-party website or service.</p>

<p>Your interactions with organizations and/or individuals found on or through the Services, including payment and delivery of goods or services, and any other terms, conditions, warranties or representations associated with such dealings, are solely between you and such organizations and/or individuals. You should make whatever investigation you feel necessary or appropriate before proceeding with any online or offline transaction with any of these third parties. You agree that Orion Eduverse Pvt Ltd shall not be responsible or liable for any loss or damage of any sort incurred as the result of any such dealings.</p>

<p>If there is a dispute between participants on this site or Services, or between users and any third party, you agree that Orion Eduverse Pvt Ltd is under no obligation to become involved. In the event that you have a dispute with one or more other users, you release Orion Eduverse Pvt Ltd, its directors, officers, employees, agents, and successors from claims, demands, and damages of every kind or nature, known or unknown, suspected or unsuspected, disclosed or undisclosed, arising out of or in any way related to such disputes and/or our Services. Certain contents of the Application/Services/products are developed on the concepts covered in the structured curriculum syllabus prescribed for students of various courses, whereas other contents may not be based on any particular curriculum. The usage of the Application/Services/products is not endorsed as a substitution to the curriculum based education provided by the educational institutions but is intended to supplement the same by explaining and presenting the concepts in a manner enabling easy understanding. The basic definitions and formulae of the subject matter</p>

<p>would remain the same. The Company acknowledges that there are various means of delivering structured curriculum pedagogy and inclusion of methods in the Application/Services/products does not imply endorsement of any particular method nor exclusion imply disapproval. Subscription to the Application or usage of our Services/Website/products does not in any manner guarantee admission to any educational institutions</p>

<p>or passing of any exams or achievement of any specified percentage of marks in any examinations.</p>

<p></p>

<p>Will Orion Eduverse Pvt. Ltd ever change the Services?</p>

<p>We&rsquo;re always trying to improve our Services, so they may change over time. We may suspend or discontinue any part of the Services, or we may introduce new features or impose limits on certain features or restrict access to parts or all of the Services. We reserve the right to remove any Content from the Services at any time, for any reason (including, but not limited to, if someone alleges you contributed that Content in violation of these Terms), in our sole discretion, and without notice.</p>

<p>&#8203;</p>

<p>Do the Services cost anything?</p>

<p>The Services may be free or we may charge a fee for using the Services at point in time at our discretion. If you are using a free version of the Services, we will notify you before any Services you are then using begin carrying a fee, and if you wish to continue using such Services, you must pay all applicable fees for such Services. Note that if you elect to receive text messages through the Services, data and message rates may apply. Any and all such charges, fees or costs are your sole responsibility. You should consult with your wireless carrier to determine what rates, charges, fees or costs may apply to your use of the Services.</p>

<p> Paid Services. Certain of our Services may be subject to payments now or in the future (the &ldquo;Paid Services&rdquo;). Please note that any payment terms presented to you in the process of using or signing up for a Paid Service for now or in future are deemed part of these Terms. For example, some Paid Services may charge a fee for downloading or installing the Services through the applicable App Store linked to your specific device. You agree to comply with, and your license to use our mobile application (&ldquo;My Adventure Book&rdquo;) is conditioned upon your compliance with, such App Store terms and conditions. Any refunds relating to My Adventure Book or inquiries regarding refunds relating to My Adventure Book shall be handled solely by the applicable App Store in accordance with such App Store&rsquo;s terms and conditions.</p>

<p></p>

<p> Billing:</p>

<p> In-App Purchases. Through our mobile application, you may purchase Paid Services, including certain goods or features designed to enhance the performance of the Services (&ldquo;In-App Purchase&rdquo;). When you make an In-App Purchase, you are doing so through either the Apple iTunes service or the Google Play service and you are agreeing to their respective Terms and Conditions, available at http://www.apple.com/legal/internet-services/itunes/us/terms.html and http://play.google.com/intl/en_us/about/play-terms.html.<br />
 Website. We are not responsible for any error by, or other acts or omissions of, any App Store. By choosing to use Paid Services, you agree to pay us, through the App Store, all charges at the prices then in effect for any use of such Paid Services in accordance with the applicable payment terms.</p>

<p></p>

<p> Recurring Billing. Some of the Paid Services may consist of an initial period, for which there is a one-time charge, followed by recurring period charges as agreed to by you. By choosing a recurring payment plan, you acknowledge that such Services have an initial and recurring payment feature and you accept responsibility for all recurring charges prior to cancellation. We may submit periodic charges (e.g., monthly) without further authorization from you, until you provide prior notice (receipt of which is confirmed by us) that you have terminated this authorization or wish to change your payment method. Such notice will not affect charges submitted before we reasonably could act. To terminate your authorization or change your payment method, go to the subscription management section of the applicable app store.</p>

<p> Change in Amount Authorized. If the amount to be charged to your Billing Account varies from the amount you preauthorized (other than due to the imposition or change in the amount of state sales taxes), you have the right to receive, and we shall provide, notice of the amount to be charged and the date of the charge before the scheduled date of the transaction. Any agreement you have with your payment provider will govern your use of your Payment Method. You agree that we may accumulate charges incurred and submit them as one or more aggregate charges during or at the end of each billing cycle.</p>

<p> Auto-Renewal for Paid Services. Unless you opt out of auto-renewal at least twenty-four (24) hours before the end of your then-current subscription period, which can be done through your account settings, any Paid Services you have signed up for will be automatically extended for successive renewal periods of the same duration as the subscription term originally selected, at the then-current non-promotional rate. To change or resign your Paid Services at any time, cancel your subscription through your account settings. If you terminate a Paid Service, you may use your subscription until the end of your then-current term, and your subscription will not be renewed after your then-current term expires. However, you will not be eligible for a prorated refund of any portion of the subscription fee paid for the then-current subscription period. If you do not want to continue to be charged on a recurring monthly basis, you must cancel the applicable Paid Service through your account settings, or terminate your My Adventure Book account before the end of the recurring term. Paid Services cannot be terminated before the end of the period for which you have already paid, and except as expressly provided in these terms, My Adventure Book will not refund any fees that you have already paid.</p>

<p> Reaffirmation of Authorization. Your non-termination or continued use of a Paid Service reaffirms that we are authorized to charge your Payment Method for that Paid Service. We may submit those charges for payment and you will be responsible for such charges. This does not waive our right to seek payment directly from you. Your charges may be payable in advance, in arrears, per usage, or as otherwise described when you initially selected to use the Paid Service.</p>

<p> Current Information Required. You must provide current, complete and accurate information for your billing account. You must promptly update all information to keep your billing account current, complete and accurate (such as a change in billing address, credit card number, or credit card expiration date), and you must promptly notify us or our payment processor if your payment method is canceled (e.g., for loss or theft) or if you become aware of a potential breach of security, such as the unauthorized disclosure or use of your user name or password. Changes to such information can be made at the subscription management section of the applicable app store. If you fail to provide any of the foregoing information, you agree that we may continue charging you for any use of paid services under your billing account unless you have terminated your paid services as set forth above.</p>

<p> Free Trials and Other Promotions. Any free trial or other promotion that provides access to a Paid Service must be used within the specified time of the trial. You must stop using a Paid Service before the end of the trial period in order to avoid being charged for that Paid Service. If you cancel prior to the end of the trial period and are inadvertently charged for a Paid Service, please contact us at contact@myadventurebook.org</p>

<p></p>

<p>I use the My Adventure Book App available via the Apple App Store &ndash; should I know anything about that?</p>

<p>&#8203;</p>

<p>These Terms apply to your use of all the Services, including My Adventure Book available via the Apple, Inc. (&ldquo;Apple&rdquo;) App Store, but the following additional terms also apply to the My Adventure Book:</p>

<p>Both you and Orion Eduverse Pvt. Ltd acknowledge that the Terms are concluded between you and Orion Eduverse Pvt. Ltd only, and not with Apple, and that Apple is not responsible for the Application or the Content;</p>

<p> My Adventure Book is licensed to you on a limited, non-exclusive, non-transferrable, non-sublicensable basis, solely to be used in connection with the Services for your private, personal, non-commercial use, subject to all the terms and conditions of these Terms as they are applicable to the Services;</p>

<p> You will only use the My Adventure Book in connection with an Apple device that you own or control;</p>

<p> You acknowledge and agree that Apple has no obligation whatsoever to furnish any maintenance and support services with respect to My Adventure Book;</p>

<p> In the event of any failure of My Adventure Book to conform to any applicable warranty, including those implied by law, you may notify Apple of such failure; upon notification, Apple&rsquo;s sole warranty obligation to you will be to refund to you the purchase price, if any, of My Adventure Book;</p>

<p> You acknowledge and agree that Orion Eduverse Pvt. Ltd, and not Apple, is responsible for addressing any claims you or any third party may have in relation to My Adventure Book;</p>

<p> You acknowledge and agree that, in the event of any third-party claim that My Adventure Book or your possession and use of My Adventure Book infringes that third party&rsquo;s intellectual property rights, Orion Eduverse Pvt. Ltd, and not Apple, will be responsible for the investigation, defense, settlement and discharge of any such infringement claim;</p>

<p> Both you and Orion Eduverse Pvt. Ltd acknowledge and agree that, in your use of My Adventure Book, you will comply with any applicable third-party terms of agreement which may affect or be affected by such use; and</p>

<p> Both you and Orion Eduverse Pvt. Ltd acknowledge and agree that Apple and Apple&rsquo;s subsidiaries are third-party beneficiaries of these Terms, and that upon your acceptance of these Terms, Apple will have the right (and will be deemed to have accepted the right) to enforce these Terms against you as the third-party beneficiary hereof.</p>

<p></p>

<p>Can I refer other users?</p>

<p>From time to time Orion Eduverse Pvt. Ltd may offer rewards or incentives for referring others to the Services, including to My Adventure Book. For details of any current referral offers, please see our referral page. The referring user (&ldquo;Referrer&rdquo;) may refer individuals who are neither current customers of Orion Eduverse Pvt. Ltd nor registered users of the Services (&ldquo;Referee&rdquo;). A registered user is a person that already has an existing account with Orion Eduverse Pvt. Ltd. There is no limit to the number of referrals that Referrer can make, nor the cumulative rewards or incentives that the Referrer may receive through such special offer, unless otherwise indicated. Referrer may receive the stated reward or incentive for each Referee sent by the Referrer that completes the required action described in that specific offer (such as signing up for an account or making a purchase). All Referees must be first-time recipients of the offer, and multiple referrals to the same individual will be disregarded. Orion Eduverse Pvt. Ltd reserves the right to modify or terminate any special offers at any time and to revoke from Referrer and Referee the special offer at Orion Eduverse Pvt. Ltd discretion for any reason or for no reason whatsoever. If Orion Eduverse Pvt. Ltd determines that Referrer or Referee is attempting to obtain unfair advantage or otherwise violate the terms or spirit of such special offer, Orion Eduverse Pvt. Ltd reserves the right to (a) revoke any rewards or incentives issued to either Referrer or Referee and/or (b) charge the Referrer or Referee for any rewards or incentives (1) used by Referrer or Referee prior to such revocation or (2) issued by Orion Eduverse Pvt. Ltd to any ineligible Referrer or Referee. All special offers are subject to any other terms, conditions and restrictions set forth on the Services or presented in connection with the special offer.</p>

<p>&#8203;</p>

<p>What if I want to stop using the Services?</p>

<p>You&rsquo;re free to do that at any time by contacting us at contact@myadventurebook.org ; please refer to our Privacy Policy, as well as the licenses above, to understand how we treat information you provide to us after you have stopped using our Services.</p>

<p>Orion Eduverse Pvt. Ltd is also free to terminate (or suspend access to) your use of the Services or your account for any reason in our discretion, including your breach of these Terms. Orion Eduverse Pvt. Ltd has the sole right to decide whether you are in violation of any of the restrictions set forth in these Terms.</p>

<p>Account termination may result in destruction of any Content associated with your account, so keep that in mind before you decide to terminate your account. We will try to provide advance notice to you prior to our terminating your account so that you are able to retrieve any important User Submissions you may have stored in your account (to the extent allowed by law and these Terms), but we may not do so if we determine it would be impractical, illegal, not in the interest of someone&rsquo;s safety or security, or otherwise harmful to the rights or property of Orion Eduverse Pvt. Ltd.</p>

<p>If you have deleted your account by mistake, contact us immediately at contact@myadventurebook.org &ndash; we will try to help, but unfortunately, we can&rsquo;t promise that we can recover or restore anything.</p>

<p>Provisions that, by their nature, should survive termination of these Terms shall survive termination. By way of example, all of the following will survive termination: any obligation you have to pay us or indemnify us, any limitations on our liability, any terms regarding ownership or intellectual property rights, and terms regarding disputes between us, including without limitation the arbitration agreement.</p>

<p>&#8203;</p>

<p>What else do I need to know?</p>

<p>Warranty Disclaimer. Orion Eduverse Pvt. Ltd and its licensors, suppliers, partners, parent, subsidiaries or affiliated entities, and each of their respective officers, directors, members, employees, consultants, contract employees, representatives and agents, and each of their respective successors and assigns (Orion Eduverse Pvt. Ltd and all such parties together, the &ldquo;Orion Eduverse Pvt. Ltd Parties&rdquo;) make no representations or warranties concerning the Services, including without limitation regarding any Content contained in or accessed through the Services, and the Orion Eduverse Pvt. Ltd Parties will not be responsible or liable for the accuracy, copyright compliance, legality, or decency of material contained in or accessed through the Services or any claims, actions, suits procedures, costs, expenses, damages or liabilities arising out of use of, or in any way related to your participation in, the Services. The Orion Eduverse Pvt. Ltd Parties make no representations or warranties regarding suggestions or recommendations of services or products offered or purchased through or in connection with the Services. The services and content are provided by orion eduverse pvt. Ltd (and its licensors and suppliers) on an &ldquo;as-is&rdquo; basis, without warranties of any kind, either express or implied, including, without limitation, implied warranties of merchantability, fitness for a particular purpose, non-infringement, or that use of the services will be uninterrupted or error-free. Some states do not allow limitations on how long an implied warranty lasts, so the above limitations may not apply to you.</p>

<p></p>

<p>Limitation of Liability. To the fullest extent allowed by applicable law, under no circumstances and under no legal theory (including, without limitation, tort, contract, strict liability, or otherwise) shall any of the orion eduverse pvt. Ltd parties be liable to you or to any other person for (a) any indirect, special, incidental, punitive or consequential damages of any kind, including damages for lost profits, business interruption, loss of data, loss of goodwill, work stoppage, accuracy of results, or computer failure or malfunction, (b) any substitute goods, services or technology, (c) any amount, in the aggregate, in excess of the greater of (i) one-hundred ($100) dollars or (ii) the amounts paid and/or payable by you to Orion Eduverse pvt. Ltd in connection with the services in the twelve (12) month period preceding this applicable claim or (d) any matter beyond our reasonable control. Some states do not allow the exclusion or limitation of incidental or consequential or certain other damages, so the above limitation and exclusions may not apply to you.</p>

<p></p>

<p>Indemnity. You agree to indemnify and hold the Orion Eduverse Pvt. Ltd Parties harmless from and against any and all claims, liabilities, damages (actual and consequential), losses and expenses (including attorneys&rsquo; fees) arising from or in any way related to any claims relating to (a) your use of the Services (including any actions taken by a third party using your account), and (b) your violation of these Terms.</p>

<p>Assignment. You may not assign, delegate or transfer these Terms or your rights or obligations hereunder, or your Services account, in any way (by operation of law or otherwise) without Orion Eduverse Pvt. Ltd's prior written consent. We may transfer, assign, or delegate these Terms and our rights and obligations without consent.</p>

<p>These Terms constitute an electronic record in accordance with the provisions of the Information Technology Act, 2000 and the Information Technology (Intermediaries guidelines) Rules, 2011 thereunder, as amended from time to time.</p>

<p>&#8203;</p>

<p>Choice of Law. The Terms shall be governed by and construed in accordance with the laws of India, without regard to conflict of law principles. Further, the Terms shall be subject to the exclusive jurisdiction of the competent courts located in Ranchi and You hereby accede to and accept the jurisdiction of such courts.</p>

<p>&#8203;</p>

<p>Miscellaneous. You will be responsible for paying, withholding, filing, and reporting all taxes, duties, and other governmental assessments associated with your activity in connection with the Services, provided that the Orion Eduverse Pvt. Ltd may, in its sole discretion, do any of the foregoing on your behalf or for itself as it sees fit. The failure of either you or us to exercise, in any way, any right herein shall not be deemed a waiver of any further rights hereunder. If any provision of these Terms are found to be unenforceable or invalid, that provision will be limited or eliminated, to the minimum extent necessary, so that these Terms shall otherwise remain in full force and effect and enforceable. You and Orion Eduverse Pvt. Ltd agree that these Terms are the complete and exclusive statement of the mutual understanding between you and Orion Eduverse Pvt. Ltd, and that these Terms supersede and cancel all previous written and oral agreements, communications and other understandings relating to the subject matter of these Terms. You hereby acknowledge and agree that you are not an employee, agent, partner, or joint venture of Orion Eduverse Pvt. Ltd, and you do not have any authority of any kind to bind Orion Eduverse Pvt. Ltd in any respect whatsoever.</p>

<p>Except as expressly set forth in the sections above regarding the Apple My Adventure Book and the arbitration agreement, you and Orion Eduverse Pvt. Ltd agree there are no third-party beneficiaries intended under these Terms.</p>

<p>Copyright:</p>

<p>My Adventure Book is a registered trademark of Orion Eduverse Private Ltd. All Rights Reserved.</p>




            </p>
            <button onClick={closeTermsOfService}>Close</button>
            </p>
          </div>
          
        </div>
      )}
    </footer>
  );
}

export default Footer;
